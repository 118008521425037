import {v2Request} from "./v2Request";



export const userPreferenceV2Api = {

  // 查找我的偏好设置
  async findMy() {
    return v2Request.get(`search-svr/userPreference/findMy`);
  },

  // 保存我的偏好设置
  async saveMy(preference) {
    return v2Request.post(`search-svr/userPreference/saveMy`, preference);
  }

};
