import {userPreferenceV2Api} from "../api/v2/userPreferenceV2Api";

export const userPreference = {
  namespaced: true,
  state: {
    appletPlanDetailUnlockDialogExpand: false
  },

  mutations: {
    SET_USER_PREFERENCE(state, userPreference) {
      userPreference = userPreference || {}
      for (const key in userPreference) {
        state[key] = userPreference[key]
      }
    }
  },


  actions: {

    async load({commit}) {
      const res = await userPreferenceV2Api.findMy()
      commit('SET_USER_PREFERENCE', res)
    },


    async save({commit, state}, userPreference) {
      await commit('SET_USER_PREFERENCE', userPreference)
      await userPreferenceV2Api.saveMy(state)
    }

  }

}
