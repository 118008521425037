import {sleep} from "../util";

const debounceFnMap = {}


export const debounce = function (fn, ms) {
  const symbol = Symbol()
  return async function () {
    if (debounceFnMap[symbol]) {
      return
    }

    try {
      debounceFnMap[symbol] = true
      const proms = fn.apply(this, arguments)
      if (ms) {
        proms.push(sleep(ms))
      }
      const [rst] = await Promise.all(proms)
      return rst
    } finally {
      debounceFnMap[symbol] = false
    }
  }
}
