import {v2Request} from "./v2Request";


export const planListV2Api = {

  findAppletDetailById(id) {
    return v2Request.get(`search-svr/planList/findAppletDetailById?id=${id}`)
  },


  // 获取下载链接
  async findDownloadUrl({id}) {
    return v2Request.get('search-svr/planList/findDownloadUrl', {params: {planListId: id}})
  },

  toggleCollect({planListId}) {
    return v2Request.post('planList/toggleCollect', {planListId})
  },

  toggleRecommend({planListId}) {
    return v2Request.post('planList/toggleRecommend', {planListId})
  },


  findMyCollect(params) {
    return v2Request.get(`search-svr/planList/findMyCollect`, {params})
  },

  findColumnsByPlanListId(id) {
    return v2Request.get(`search-svr/planList/findColumnsByPlanListId2`, {params: {planListId: id}})
  },


  findDownloadInfo({planListId, verifyCode, visitorToken}) {
    return v2Request.get(`/search-svr/download/planList`, {
      params: {
        planListId, verifyCode, visitorToken
      }
    })
  },


  findColumnPlans(planListId, columnId) {
    return v2Request.get(`search-svr/planList/findColumnPlans`, {params: {planListId, columnId}})
  },


  findPlanPageByPlanListId(params) {
    return v2Request.get(`search-svr/planList/findPlanPageByPlanListId`, {params})
  },


  findHasUnlock(id) {
    try {
      return v2Request.get(`search-svr/planList/findHasUnlock`, {params: {id}})
    }catch (e){
    }
  },


  async findByPlanIds(planIds) {
    return v2Request.post('search-svr/planList/findByPlanIds', planIds)
  }

};
