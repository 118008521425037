// import axios from 'axios';

import qs from 'qs'
import store from '../store';

axios.defaults.timeout = 100 * 1000;
axios.defaults.headers['Content-Type'] = 'application/json';

// axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.baseURL = '/api';
axios.defaults.withCredentials = true;


axios.interceptors.request.use(
    config => {
        config.headers.token = localStorage.token || '';
        return config;
    }, error => {
        return Promise.reject(error)
    }
);
axios.interceptors.response.use(response => {
    // 统一处理返回状态
    if (response.data.code == 403) {
        /*Toast(response.data.msg);*/
        localStorage.clear();
        // window.location.replace(window.location.origin+'/login/wechat');
      store.state.to = window.location.pathname;
      return store.dispatch('showLoginDialog');
    }
    return Promise.resolve(response.data);
}, error => {
    return Promise.reject(error);


});
const get = async (url, params) => {
    return await axios.get(url, { params });
};

const post = async (url, params, config) => {
    return await axios.post(url, params, config);
};

const upload = async (url, params) => {
    return await axios.post(url, params, {headers: {'Content-Type': 'multipart/form-data'}});
};

const put = async (url, params) => {
    return await axios.put(url, params);
};

const remove = async (url, params) => {
    return await axios.delete(url, {params});

};

export {
    get, post, put, remove,upload
}
