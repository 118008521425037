import {v2Request} from './v2Request';


export const creatorRankV2Api = {

  // 查找所有排行期数
  async findIssueByType(type) {
    return v2Request.get('creatorRank/findIssueByType', {params: {type}});
  },


  // 根据开始时间，结束时间，类型查询
  async findByBeginTimeAndEndTimeAndType({beginTime, endTime, type}) {
    return v2Request.post('creatorRank/findByBeginTimeAndEndTimeAndType', {type, beginTime, endTime});
  }

};
