import {buryPointV3Api} from "../api/v2/buryPointV3Api";
import {getBrowser} from "../config/util";
import {getDevice} from "../config/config";

export const buryPoint = {
  namespaced: true,


  state: {
    toSave: []
  },


  mutations: {

    PUSH_BURY_POINTS(state, buryPoints) {
      if (buryPoints && buryPoints.length) {
        for (const b of buryPoints) {
          b.page = window.location.href
          b.platform = getBrowser()
          b.device = getDevice()
        }
        state.toSave = [
          ...state.toSave,
          ...buryPoints
        ]
      }
    },


    CLEAR_TO_SAVE(state) {
      state.toSave = []
    }

  },


  actions: {

    startSaveInterval({state, commit}) {
      setInterval(() => {
        const buryPoints = state.toSave
        if (buryPoints && buryPoints.length) {
          buryPointV3Api.saveBatch(buryPoints)
          commit('CLEAR_TO_SAVE')
        }
      }, 5000)
    }

  }

}
