export const payTypeEnum = {
  wx: 1,
  alipay: 2
};


export const payStatusEnum = {
  notPay: 0,
  payed: 1
};


export const freeTypeEnum = {
  free: 1,       // 免费方案
  vipFree: 2,    // 会员免费
  needPay: 3     // 会员折扣
};

export function getFreeType(price, isMemberFree) {
  if (price === 0) {
    return freeTypeEnum.free
  }
  if (isMemberFree) {
    return freeTypeEnum.vipFree;
  }
  return freeTypeEnum.needPay;
}


export const prfessionOptions = [
  {label: '文案/策划', value: '文案/策划'},
  {label: '创意/设计', value: '创意/设计'},
  {label: '品牌/营销', value: '品牌/营销'},
  {label: '客户/市场', value: '客户/市场'},
  {label: '运营/产品', value: '运营/产品'},
  {label: '项目/执行', value: '项目/执行'},
  {label: '制作/技术', value: '制作/技术'},
  {label: '组织/文化', value: '组织/文化'},
  {label: '教育/研究', value: '教育/研究'},
  {label: '其他', value: '其他'},
];


export const creatorRankTypeEnum = {
  upload: 1,
  beBrowse: 2,
  beRecommend: 3,
  beCollect: 4,
  popularity: 5
}


export function getCreatorRankType(type) {
  switch (type) {
    case creatorRankTypeEnum.upload:
      return '上传榜';
    case creatorRankTypeEnum.beBrowse:
      return '浏览榜';
    case creatorRankTypeEnum.beRecommend:
      return '推荐榜';
    case creatorRankTypeEnum.beCollect:
      return '收藏榜';
    case creatorRankTypeEnum.popularity:
      return '人气榜';
    default:
      return '创作者榜';
  }
}


export function getCreatorRankTypeUrlKey(type) {
  switch (type) {
    case creatorRankTypeEnum.upload:
      return 'upload';
    case creatorRankTypeEnum.beBrowse:
      return 'browse';
    case creatorRankTypeEnum.beRecommend:
      return 'recommend';
    case creatorRankTypeEnum.beCollect:
      return 'collect';
    case creatorRankTypeEnum.popularity:
      return 'popularity';
    default:
      return 'browse';
  }
}


export const storageKey = {
  PLAN_DETAIL_RETURN_PAGE: 'planDetailReturnPage',
  COLLECTION_DETAIL_RETURN_PAGE: 'collectionDetailReturnPage',
  RECHARGE_RETURN_PAGE: 'rechargeReturnPage'
}


export const taskIdEnum = {
  FINISH_FIRST_LOGIN: 1,
  PERFECT_PERSONAL_DATA: 2,
  FINISH_RECOMMEND: 4,
  FINISH_COLLECT: 5,
  FINISH_BROWSE: 3,
  UPLOAD_PLAN: 7
}


export const planRankTypeEnum = {
  FREE: 1,
  PAY: 2
}


export const planRankDaysEnum = {
  TODAY: 1,
  WEEK: 7,
  MONTH: 30
}


export const planRankRangeTypeEnum = {
  ALL: 1,
  PLAN_TYPE_HUO_DONG: 21,
  PLAN_TYPE_YING_XIAO: 22,
  PLAN_TYPE_DUI_WAI: 23,
  PLAN_TYPE_SHE_JI_YUN_YING: 24,
  PLAN_TYPE_KE_JIAN_YAN_BAO_QI_TA: 25,
  INDUSTRY_TYPE_NAI_XIAO: 31,
  INDUSTRY_TYPE_KUAI_XIAO: 32,
  INDUSTRY_TYPE_NEI_RONG: 33,
  INDUSTRY_TYPE_FU_WU: 34,
  INDUSTRY_TYPE_BU_XIAN: 35
}


export const goodsCouponTypeEnum = {
  REDUCTION: 1,
  DISCOUNT: 2
}


export const orderStatusEnum = {
  CREATED: 1,
  PAYED: 2,
  EXPRESSED: 3,
  COMPLETE: 4,
  REFUNDING: 10,
  CANCELED: 11,
}


export const orderStatusOptions = [
  {label: '未支付', value: orderStatusEnum.CREATED},
  {label: '已支付', value: orderStatusEnum.PAYED},
  {label: '运输中', value: orderStatusEnum.EXPRESSED},
  {label: '已完成', value: orderStatusEnum.COMPLETE},
  {label: '退款中', value: orderStatusEnum.REFUNDING},
  {label: '已取消', value: orderStatusEnum.CANCELED},
]


export const goodsTypeEnum = {
  ACTUAL_PRODUCT:1,
  COURSE: 2
}
