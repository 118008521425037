<template>
    <div class="toast">
        <van-icon name="success" v-if="type=='success'"/>
        <van-icon name="cross" v-if="type=='error'"/>
        <van-icon name="fail" v-if="type=='info'"/>
        <p>{{content}}</p>
        <span v-if="desc">{{desc}}</span>
    </div>


</template>
<script>
    export default {
        name: "Toast",
        data() {
            return {
                type: "",
                content: "",
                desc: '',
                duration: 2000
            };
        },
        mounted() {
            setTimeout(() => {
                this.$el.parentNode.removeChild(this.$el);
            }, this.duration);
        }
    };
</script>
<style lang="less" scoped>

    .toast {
        position: fixed;
        left: calc((100% - 140px) / 2);
        top: calc((100% - 140px) / 2);
        z-index: 9999;
        width: 140px;
        height: 140px;
        background: rgba(17, 17, 17, 0.75);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        text-align: center;
        i {
            color: #fff;
            font-size: 45px;
            font-weight: 900;
        }

        p {
            font-size: 14px;
            color: #fff;
        }

        span {
            font-size: 12px;
            color: #fff;
        }
    }


</style>
