<template>
  <div id="app">
    <my-notify ref="myNotify"/>
    <login-dialog/>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view  v-if="!$route.meta.keepAlive"/>
    <div id="globalLoading">
      <img src="https://cdn.001ppt.cn/pc/img/global_loading.svg" alt="">
    </div>

    <preview-single-image/>
  </div>
</template>

<script>
import wxValidate from '@/config/wx.validate'
import LoginDialog from '@/components/loginDialog';
import {vipRightV2Api} from "./api/v2/vipRightV2Api";
import PreviewSingleImage from "./components/previewSingleImage";
import MyNotify from "./components/myNotify";
import Vue from 'vue'

export default {
  mixins: [wxValidate],
  components: {MyNotify, PreviewSingleImage, LoginDialog},

  async created() {
    this.getIcon().then();
    this.loadIsFreeCaseUnitDay().then();

    if(window.location.search){
      const query = {}
      const split = window.location.search.slice(1).split('&')
      for(const s of split){
        const subSplit = s.split('=')
        const key = subSplit[0]
        const val = subSplit[1]
        query[key] = val
        if (query.shareUserId && query.shareUserId.length) {
          localStorage.shareUserId = query.shareUserId
          if(window.location.pathname.startsWith('/caseDetail/')){
            localStorage.sharePlanId = window.location.pathname.replace('/caseDetail/', '')
          }
        }
      }
    }

  },


  mounted() {
    Vue.prototype.$myNotify = this.$refs.myNotify

  },


  methods: {
    async getIcon() {
      const configMap = await this.$store.dispatch('config/loadConfig')
      const link = document.createElement('link')
      link.href = configMap.WEB_TAG_ICON
      link.rel = 'shortcut icon'
      document.head.append(link)
      localStorage.setItem('case', configMap.INVITE_SHARE)
    },


    async loadIsFreeCaseUnitDay() {
      const vipRight = await vipRightV2Api.findById(3);
      this.$store.commit('SET_IS_FREE_CASE_UNIT_DAY', vipRight.freeCaseUnit === 1)
    }
  },
}

</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

#nav {
  padding: 30px;
  font-size: 32px;
  line-height: 1.2;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #fc9153;
}

#globalLoading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #151d36cc;
  align-items: center;
  justify-content: center;
  z-index: 999;
  display: none;
}

#globalLoading img {
  height: 50px;
  width: 50px;
}
</style>
<style lang="less">
.van-tabbar-title-dot {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 4px;
    width: 4px;
    background: #ee4a50;
    border-radius: 100px;
    margin-left: 1px;
  }
}

.custom-van-tabs {
  .van-tab__text--ellipsis {
    overflow: visible;
  }
}
</style>
