<template>
  <div class="my-notify" :class="{'my-notify-visible': visible}">
    <div class="text block">
      <template v-if="options.type">
        <van-icon name="checked" v-if="options.type==='success'" style="margin-right: 4px"/>
        <van-icon name="clear" v-else-if="options.type==='error'" style="margin-right: 4px"/>
        <van-icon name="info" v-else-if="options.type==='info'" style="margin-right: 4px"/>
      </template>
      {{ options.text }}
    </div>
    <div v-if="options.rightText" class="block" @click="options.onClick()">
      {{ options.rightText }}
      <van-icon name="arrow"/>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      timeout: null,
      visible: false,
      options: {
        duration: 2000,
        text: '',
        type: '',
        rightText: '',
        onClick: () => {
        }
      }
    }
  },


  methods: {
    show(options) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.visible = false
      this.options = options
      this.visible = true

      const duration = options.duration || 2000
      this.timeout = setTimeout(() => {
        this.visible = false
        clearTimeout(this.timeout)
      }, duration)
    }
  }

}
</script>

<style scoped lang="less">
.my-notify {
  position: fixed;
  bottom: 50px;
  left: 8px;
  right: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background: #151D36;
  color: #fff;
  font-weight: bolder;
  transition: transform .1s ease-in, opacity .2s ease;
  z-index: -1;
  opacity: 0;
}

.my-notify-visible {
  transform: translateY(-16px);
  z-index: 1000000;
  opacity: 1;
}

.block {
  display: flex;
  align-items: center;
}
</style>
