<template>
  <div class="preview-single-image" style="display: none" :class="{
    visible:$store.state.previewSingleImage.visible,
  }" @click="hide">
    <div class="shade"></div>
    <div class="image-container">
      <van-image
        :width="$store.state.previewSingleImage.options.width"
        :height="$store.state.previewSingleImage.options.height"
        :fit="$store.state.previewSingleImage.options.fit"
        :src="$store.state.previewSingleImage.options.src">
        <template slot="loading">
          <van-loading color="#fff" type="spinner" vertical>
            加载中
          </van-loading>
        </template>

      </van-image>
    </div>
  </div>
</template>

<script>

export default {

  methods: {
    hide() {
      this.$store.commit('previewSingleImage/hide')
    }
  }

}
</script>

<style scoped lang="less">
.preview-single-image {
  position: relative;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;

  .shade {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #151D36;
    opacity: .7;
  }

  .image-container {
    transform: translateY(-10px);
    transition: transform .4s ease-in-out;
  }

  &.visible {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: auto;
    height: auto;

    .image-container {
      transform: translateY(0);
    }
  }
}
</style>
