import {planV2Api} from "../api/v2/planV2Api";
import {payTypeEnum} from "./enums";

export async function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  })
}


export function copyToClipBoard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}


export function saveImgByUrl(Url) {
  const blob = new Blob([''], {type: 'application/octet-stream'});
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = Url;
  a.download = Url.replace(/(.*\/)*([^.]+.*)/ig, "$2").split("?")[0];
  const e = document.createEvent('MouseEvents');
  e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  a.dispatchEvent(e);
  URL.revokeObjectURL(url);
}

let hasShowGlobalLoading = false;

export function showGlobalLoading(force) {
  if (!hasShowGlobalLoading || force) {
    $('#globalLoading').css({display: 'flex'});
    hasShowGlobalLoading = true;
  }
}

export function closeGlobalLoading() {
  $('#globalLoading').css({display: 'none'})
}


// 对象转换为queryString的形式 {name:'abc', age:12} =>  name=abc&age=12
export function objToQueryString(obj) {
  if (!obj) {
    return '';
  }
  const queries = [];
  for (const key in obj) {
    const value = obj[key];
    if (!value) {
      continue;
    }
    queries.push(`${key}=${value}`);
  }
  return queries.join('&');
}


export function isIos() {
  const u = navigator.userAgent, app = navigator.appVersion;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}


/**
 * 获取元素相对浏览器的偏移
 * @param el
 * @returns {{x: number, y: number}|boolean|{x: *, y: *}}
 */
export function getElementPos(el) {
  let ua = navigator.userAgent.toLowerCase();
  let isOpera = (ua.includes('opera'));
  let isIE = (!ua.includes('msie') && !isOpera); // not opera spoof
  if (el.parentNode === null || el.style.display === 'none') {
    return false;
  }
  let parent = null;
  let pos = [];
  let box;
  if (el.getBoundingClientRect)    //ie  ：经过测试好像目前所有类型的浏览器都实现了这个方法
  {
    box = el.getBoundingClientRect();
    let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    let scrollLeft = Math.max(document.documentElement.scrollLeft, document.body.scrollLeft);
    return {x: box.left + scrollLeft, y: box.top + scrollTop};
  } else if (document.getBoxObjectFor)    // gecko
  {
    box = document.getBoxObjectFor(el);
    let borderLeft = (el.style.borderLeftWidth) ? parseInt(el.style.borderLeftWidth) : 0;
    let borderTop = (el.style.borderTopWidth) ? parseInt(el.style.borderTopWidth) : 0;
    pos = [box.x - borderLeft, box.y - borderTop];
  } else    // safari & opera
  {
    pos = [el.offsetLeft, el.offsetTop];
    parent = el.offsetParent;
    if (parent !== el) {
      while (parent) {
        pos[0] += parent.offsetLeft;
        pos[1] += parent.offsetTop;
        parent = parent.offsetParent;
      }
    }
    if (ua.indexOf('opera') !== -1 || (ua.indexOf('safari') !== -1 && el.style.position === 'absolute')) {
      pos[0] -= document.body.offsetLeft;
      pos[1] -= document.body.offsetTop;
    }
  }
  if (el.parentNode) {
    parent = el.parentNode;
  } else {
    parent = null;
  }
  while (parent && parent.tagName != 'BODY' && parent.tagName != 'HTML') { // account for any scrolled ancestors
    pos[0] -= parent.scrollLeft;
    pos[1] -= parent.scrollTop;
    if (parent.parentNode) {
      parent = parent.parentNode;
    } else {
      parent = null;
    }
  }
  return {x: pos[0], y: pos[1]};
}

export function getBrowser() {
  const ua = window.navigator.userAgent.toLowerCase();
  let result = '';
  if (ua.match(/MicroMessenger/i) === null) {
    result = 'h5';
  } else {
    result = 'wechat';
  }
  return result;
}

export function getPaySubType(payType) {
  const isWechat = getBrowser() === 'wechat';
  if (isWechat) {
    return 11;
  }
  return payType === 1 ? 13 : 21;
}


/**
 * 处理支付
 */
export async function processPay(payType, paySubType, payForm) {
  return new Promise((resolve, reject) => {
    // 支付宝支付的情况
    if (payType === payTypeEnum.alipay) {
      return document.write(payForm);
    }

    // 微信支付，h5环境
    if (payType === payTypeEnum.wx && paySubType === 13) {
      const res = JSON.parse(payForm);
      window.location.replace(res.mwebUrl + '&redirect_url=' + encodeURIComponent(location.origin + '/result/wechat/' + res.bizNo + '?inner_redirect=' + location.href));
      return;
    }

    if (payType === payTypeEnum.wx && paySubType === 11) {
      const res = JSON.parse(payForm);
      wx.chooseWXPay({
        timestamp: res.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: res.nonceStr, // 支付签名随机串，不长于 32 位
        package: res.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: res.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: res.paySign, // 支付签名
        success: async (r) => {
          // 支付成功后的回调函数
          this.hide();
          resolve()
        },
        fail: async (e) => {
          reject(e);
        },
      });
    }
  })
}


export function planSO2rankItem(r, i) {
  return {
    planId: r.plan.id,
    rankIndex: i + 1,
    planCover: r.plan.cover,
    createAvatar: r.creator.avatar ? r.creator.avatar.replace('http://', 'https://') : '',
    createUsername: r.creator.nickName,
    planTitle: r.plan.planTitle
  }
}


// 下载文件
// 为防止 window.open失效，这里使用iframe下载
export function downloadByUrl(url) {
  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.style.height = '1px';
  iframe.style.width = '1px';
  iframe.style.position = 'fixed';
  iframe.style.top = '-1000px';
  iframe.style.left = '-1000px';
  document.body.appendChild(iframe);
}


export function timeRandomId() {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';

  // 获取当前时间戳（精确到秒）
  const timestamp = Math.floor(Date.now() / 1000);

  // 将时间戳作为 ID 的前缀
  id += timestamp;

  // 生成四位随机字符作为后缀
  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }

  return id;
}


export async function querySelector(selector) {
  let dom = selector
  do {
    dom = document.querySelector(selector)
    if (!dom) {
      await sleep(10)
    }
  } while (!dom)
  return dom
}



export async function hasRecordPermission() {
  try {
    const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
    return permissionStatus.state === 'granted';
  } catch (error) {
    console.error('无法查询录制音频权限状态:', error);
    return false;
  }
}


export async function requestRecordPermission() {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    console.log('用户已授予录音权限');

    // 停止所有音频轨道以释放资源
    stream.getTracks().forEach(track => track.stop());

    return true;
  } catch (error) {
    console.error('用户拒绝了录音权限或发生错误:', error);
    return false;
  }
}
