import {ticketV2Api} from "../api/v2/ticketV2Api";

export const ticket = {
  namespaced: true,

  state: {
    hasLoad: false,
    allTickets: []
  },


  mutations: {

    setAllTickets(state, data) {
      for (const t of data) {
        if(Object.prototype.toString.call(t.expireTime) === '[object String]') {
          t.expireTime = new Date(t.expireTime.replace(/-/g, '/'));
        }
        const endOfToday = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1);
        if (t.expireTime.getTime() > endOfToday.getTime()) {
          t.expireTime = endOfToday;
        }
      }
      state.allTickets = data
    },


    setHasLoad(state, hasLoad) {
      state.hasLoad = hasLoad
    }

  },


  actions: {
    // 加载当前用户所有的抵扣券
    async loadAllTickets({state, commit}) {
      if (state.hasLoad) {
        commit('setHasLoad', true)
      }

      try {
        const res = await ticketV2Api.findMy()
        commit('setAllTickets', res)
      } catch (e) {
        console.error(e)
        commit('setHasLoad', false)
      }
    }
  },


  getters: {

    currTicket(state, getters, {member}) {
      const vipInfo = member
      const tickets = state.allTickets
      if (!tickets || tickets.length === 0) {
        return null
      }
      if (tickets.length === 1) {
        return tickets[0]
      }

      if (vipInfo.vipRightId === '4' || vipInfo.vipRightId === '5') {
        return tickets.find(t => t.vipRightId === vipInfo.vipRightId)
      }
      if (vipInfo.lastExpireVipRightId === '4' || vipInfo.lastExpireVipRightId === '5') {
        return tickets.find(t => t.vipRightId === vipInfo.lastExpireVipRightId)
      }
    }

  }

}
