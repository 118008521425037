import {v2Request} from './v2Request';


export const userRankV2Api = {


  async rankByBrowseTimes({current, size, daysInRange}) {
    return v2Request.post(`search-svr/userRank/rankByBrowseTimes`, {current, size, daysInRange, ignoreReturnPlans: true});
  },

  async rankByRecommendTimes({current, size, daysInRange}) {
    return v2Request.post(`search-svr/userRank/rankByRecommendTimes`, {current, size, daysInRange, ignoreReturnPlans: true});
  },

  async rankByCollectTimes({current, size, daysInRange}) {
    return v2Request.post(`search-svr/userRank/rankByCollectTimes`, {current, size, daysInRange, ignoreReturnPlans: true});
  },

  async rankByUnlockTimes({current, size, daysInRange}) {
    return v2Request.post(`search-svr/userRank/rankByUnlockTimes`, {current, size, daysInRange, ignoreReturnPlans: true});
  },

  async rankByAward({current, size, daysInRange}) {
    return v2Request.post(`search-svr/userRank/rankByAward`, {current, size, daysInRange, ignoreReturnPlans: true});
  },

  async loadMy({daysInRange}) {
    return v2Request.post(`search-svr/userRank/findMy`, {daysInRange})
  }

};
