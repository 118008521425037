// import axios from 'axios';


import store from "../../store";

/**
 * 仅支持 /api/v2/web
 */
export const v2Request = axios.create({
    baseURL: '/api/applet/v2'
});


// 请求拦截器，携带token请求
v2Request.interceptors.request.use((request) => {
    const token = localStorage.getItem("token") || "";
    request.headers['token'] = token;
    return request;
});


// 响应拦截器，若返回数据存在 code，则视为失败，根据code的值来判断是否跳登录还是抛出异常给页面进行message处理
v2Request.interceptors.response.use((res) => {
    const {data} = res;
    if (!data || !data.code) {
        return data;
    }

    // 处理接口异常
    const {code, msg} = data;

    // 未登录
    if (code === 403) {
      localStorage.clear();
      // window.location.replace(window.location.origin+'/login/wechat');
      store.state.to = window.location.pathname;
      return store.dispatch('showLoginDialog');
    }

    // 其他错误直接抛出
    if(msg==='操作成功'){
      return data
    }
    throw new Error(msg);
});
