import {v2Request} from "./v2Request";
import Toast from '../../components/toast'
import {sleep} from "../../config/util";

/**
 * 用户相关api
 */
export const taskFinishRecordV2Api = {

  async notifyByTaskId(taskId) {
    await sleep(1000)
    const res = await v2Request.get(`/search-svr/task/notifyTask`, {params:{taskId}});
    if (res) {
      let str = `${res.taskName}, 严值 +${res.taskYanzhi}`;
      Toast.success(str)
    }
  },


  // 完成每日登录任务
  async finishEverydayFirstLogin() {
    const res = await v2Request.get(`/task/finishEverydayLoginTask`);
    if (res) {
      let str = `登录成功, 严值 +${res.taskYanzhi}`;
      // if(res.userLevel && res.userLevel>1){
      //   str+=`\r\n(当前用户等级: LV.${res.userLevel}, 已奖励${res.awardPercent/100}倍严值)`
      // }
      Toast.success(str)
    }
  }

};
