import {configV2Api} from "../api/v2/configV2Api";

export const config = {
  namespaced: true,

  state: {
    hasInit: false,
    WEB_TAG_ICON: '',
    INVITE_SHARE: '',

    // visible option
    "CREATE_TIME_VISIBLE": false,
    "LOOK_FREE_VISIBLE": false,
    "FILE_EXT_VISIBLE": false,
    "FILE_SIZE_VISIBLE": false,
    "ISBRAND": false,
    "ISUNIONCREATE": false,
    "ISPROCIRCLE": false,
    "BROWSE_TIMES": false,
    "RECOMMEND_TIMES": false,
    "COLLECT_TIMES": false,
    "DOWNLOAD_TIMES": false,
    'PLACE_VISIBLE': false,
  },


  getters: {

    visibleOption(state) {
      return {
        "browseTimesVisible": state.BROWSE_TIMES === 'Y',
        "recommendTimesVisible": state.RECOMMEND_TIMES === 'Y',
        "collectTimesVisible": state.COLLECT_TIMES === 'Y',
        "downloadTimesVisible": state.DOWNLOAD_TIMES === 'Y',
        "placeVisible": state.PLACE_VISIBLE === 'Y',
        "createTimeVisible": state.CREATE_TIME_VISIBLE === 'Y',
        "lookFreeVisible": state.LOOK_FREE_VISIBLE === 'Y',
        "fileExtVisible": state.FILE_EXT_VISIBLE === 'Y',
        "fileSizeVisible": state.FILE_SIZE_VISIBLE === 'Y',
        "brankVisible": state.ISBRAND === 'Y',
        "brank1Visible": state.ISUNIONCREATE === 'Y',
        "procircleVisible": state.ISPROCIRCLE === 'Y'
      }
    }

  },


  mutations: {
    SET_CONFIG(state, configs) {
      configs = configs || []
      for (const c of configs) {
        state[c.code] = c.value
      }

      // 标签栏图标
      if (state.WEB_TAG_ICON) {
        state.WEB_TAG_ICON = state.WEB_TAG_ICON.replace('http://', 'https://')
      }
    }
  },


  actions: {
    async loadConfig({state, commit}) {
      try {
        if (!state.hasInit) {
          state.hasInit = true
          const codes = Object.keys(state).filter(k => k !== 'hasInit')
          const configs = await configV2Api.findByCodes(codes)
          commit('SET_CONFIG', configs)
        }
        return state
      } catch (e) {
        console.error(e)
        state.hasInit = false
      }
    }
  }

}
