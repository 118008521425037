import {user} from "@/api";

export default {
    data(){
        return {
            appId: process.env.VUE_APP_APPID,
            ticket: '',
            timestamp: parseInt(new Date().getTime() / 1000),
            nonceStr: 'Wm3WZYPz0wzccnW',
            debug: false
        }
    },
    created(){

    },


    methods: {

        async wxSignature(){
            if(!this.ticket){
                const {data} = await user.getTicket();
                this.ticket = data;
            }
            let signature = hex_sha1(`jsapi_ticket=${this.ticket}&noncestr=${this.nonceStr}&timestamp=${this.timestamp}&url=${location.href}`);
            localStorage.setItem('signature',signature);
            // console.log(`ticket=>>${this.ticket}`);
            // console.log(`nonceStr=>>${this.nonceStr}`);
            // console.log(`timestamp=>>${this.timestamp}`);
            // console.log(`appId=>>${this.appId}`);
            // console.log(`url=>>${location.href}`);
            // console.log(`signature=>>${signature}`);
            await this.validateWeChat(signature);

        },


        async validateWeChat(signature){
            //配置微信
            return new Promise(resolve => {
                wx.config({
                    debug: this.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: this.appId, // 必填，公众号的唯一标识
                    timestamp: this.timestamp, // 必填，生成签名的时间戳
                    nonceStr: this.nonceStr, // 必填，生成签名的随机串
                    signature: signature,
                    jsApiList: [
                        'closeWindow',
                        'chooseImage',
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        'chooseWXPay'
                    ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });


                wx.ready(_ => {
                    //微信初始化成功
                    resolve(true);
                });

                wx.error(_ => {
                    //微信初始化失败
                    resolve(false);
                })
            })



        }
    }
}
