import {v2Request} from "./v2Request";


export const vipRightV2Api = {

  /**
   * 发起加入会员支付
   */
  startPayVipRight({vipRightId, useYanzhiPay, paySubType, payType, inviteCode,fromPlanId,packageId}) {
    const returnUrl = 'https://m.001ppt.com/record?tab=CONSUME';
    return v2Request.post(`/vipRight/startPayVipRight`, {
      payType,
      paySubType,
      vipRightId,
      useYanzhiPay,
      inviteCode,
      returnUrl,
      fromPlanId,
      packageId
    });
  },


  notifyJoin(id) {
    return v2Request.post(`/vipRight/notifyJoin`, {id});
  },


  findMyLastExpireVip() {
    return v2Request.get(`/vipRight/findMyLastExpireVip`);
  },


  notifyExpire(id){
    return v2Request.post(`/vipRight/notifyExpire`, {id});
  },


  findById(id) {
    return v2Request.get(`/search-svr/vipRight/findById`, {params:{id}});
  },

  findAll(){
    return v2Request.get(`/vipRight/findAll`);
  },

  findAllForPay(){
    return v2Request.get(`/search-svr/vipRight/findAllForPay`)
  },

  findAllForPay2(){
    return v2Request.get(`/search-svr/vipRight/findAllForPay2`)
  },


  findNotifyDialog(vipRightId) {
    return v2Request.get(`/vipRightUser/findNotifyDialog`,{params:{vipRightId}})
  },


  randomGetVipBuyRecord(){
    return v2Request.get(`/vipRightUser/randomGet`)
  }

};
