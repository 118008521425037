import {
  get,
  post,
  remove,
  upload,
  put
} from './api.config';
import {v2Request} from "./v2/v2Request";

export const plan = {
  remind: params => post(`/applet/statistics/demand/add`, params),                                   //提醒收录
  // list: params => get(`/applet/projPlan/getListByPage`, params),                      //方案列表

  history: _ => get(`/applet/plan/search/findList`),                                  //热门搜索

  listGroup: async params => {
    const data = await v2Request.get('search-svr/planList/search',{params})
    data.size = data.pageSize
    return {
      code : 1,
      data
    }
  },                      //方案合集列表

  info: id => get(`/applet/projPlan/getDetailById/${id}`),                            //方案详情

  downloadPlan: params => post(`/applet/plan/download/add`, params),                  //下载方案

  downloadPlanList: params => post(`/applet/plan/list/download/add`, params),                  //下载方案

  unlockAdd: params => post(`/applet/plan/pay/add`, params),                  //解锁方案

  unlockAddList: params => post(`/applet/plan/list/pay/add`, params),                  //解锁方案合集

  tree: _ => get(`/applet/plan/category/findByParentNames?parentNames=授权类型&parentNames=按方案分&parentNames=按活动分&parentNames=按行业分&parentNames=场地类型&parentNames=参与人数&parentNames=项目预算`),                                    //查询所有分类

  schemeGroup: params => get(`/api/applet/plan/list/add`, params),

  collect: params => post(`/applet/plan/collect/add`, params),                                //收藏方案

  collectList: params => post(`/applet/plan/list/collect/add`, params),                                //收藏方案合集

  // cancelCollect: projPlanId => remove(`/applet/plan/collect/cancel`, {projPlanId}),                                //取消收藏方案

  cancelCollectList: planListId => remove(`/applet/plan/list/collect/cancel`, {planListId}),                                //取消收藏方案合集

  // like: params => post(`/applet/plan/recommend/add`, params),                                //点赞方案

  likeList: params => post(`/applet/plan/list/recommend/add`, params),                                //点赞方案合集

  // dislike: projPlanId => remove(`/applet/plan/recommend/cancel`, {projPlanId}),                                //取消点赞方案

  dislikeList: planListId => remove(`/applet/plan/list/recommend/cancel`, {planListId}),                                //取消点赞方案合集

  // findByParentId: parentId => get(`/applet/plan/category/findByParentId`, {parentId}),  //通过父级id找分类

  // findByParentName: parentName => get(`/applet/plan/category/findByParentName`, {parentName}),  //通过父级名称找分类


  allPlanCategories: () => {
    return v2Request.get('search-svr/planCategory/findAll')
  }
};
