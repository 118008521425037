<template>
    <div class="card" @click="click">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "card",
        methods:{
            click(){
                this.$emit('click');
            }
        }
    }
</script>

<style scoped lang="less">
    .card{
        box-shadow:0px 8px 10px 0px rgba(0,0,0,0.08);
        border-radius: @card-radius;
    }
</style>
