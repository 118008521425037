// import Vue from 'vue';

export function formatDate(val, fmt) { //author: meizz
  if(val === undefined || val === null){
    return '';
  }
  if(val.substr){
    val = val.replace(/-/g,'/');
    val = new Date(val);
  }
  var o = {
    "M+": val.getMonth() + 1,                 //月份
    "d+": val.getDate(),                    //日
    "h+": val.getHours(),                   //小时
    "m+": val.getMinutes(),                 //分
    "s+": val.getSeconds(),                 //秒
    "q+": Math.floor((parseFloat(val.getMonth()) + 3) / 3), //季度
    "S": val.getMilliseconds()             //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (val.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

Vue.filter('date', (val) => {
  if(!val || !val.length){
    return '';
  }
  const date = new Date(val);
  return formatDate(date, 'yyyy-MM-dd')
});
