import {v2Request} from "./v2Request";


export const buryPointV3Api = {

  saveBatch(data) {
    v2Request.post('buryPoint/saveBatch', data)
  }

}
