export default {
  install(Vue) {
    // v-longpress 指令
    Vue.directive('longpress', {
      bind(el, binding) {
        if (typeof binding.value !== 'function') {
          console.warn(`Expect a function, got ${typeof binding.value}`);
          return;
        }

        let pressTimer = null;

        const start = (event) => {
          if (event.type === 'click' && event.button !== 0) {
            return;
          }

          if (pressTimer === null) {
            pressTimer = setTimeout(() => {
              handler();
            }, 200); // 长按时间
          }
        };

        const cancel = () => {
          if (pressTimer !== null) {
            clearTimeout(pressTimer);
            pressTimer = null;
          }
        };

        const handler = (event) => {
          binding.value(event);
        };

        el.addEventListener('mousedown', start);
        el.addEventListener('touchstart', start);
        el.addEventListener('click', cancel);
        el.addEventListener('mouseout', cancel);
        el.addEventListener('touchend', cancel);
        el.addEventListener('touchcancel', cancel);

        el._cancel = cancel;
      },
      unbind(el) {
        el.removeEventListener('mousedown', el._start);
        el.removeEventListener('touchstart', el._start);
        el.removeEventListener('click', el._cancel);
        el.removeEventListener('mouseout', el._cancel);
        el.removeEventListener('touchend', el._cancel);
        el.removeEventListener('touchcancel', el._cancel);
        delete el._cancel;
      }
    });

    // v-longpress-end 指令
    Vue.directive('longpress-end', {
      bind(el, binding) {
        if (typeof binding.value !== 'function') {
          console.warn(`Expect a function, got ${typeof binding.value}`);
          return;
        }

        const handler = (event) => {
          binding.value(event);
        };

        el._mouseupHandler = handler;

        el.addEventListener('mouseup', handler);
        el.addEventListener('touchend', handler);
      },
      unbind(el) {
        el.removeEventListener('mouseup', el._mouseupHandler);
        el.removeEventListener('touchend', el._mouseupHandler);
        delete el._mouseupHandler;
      }
    });
  }
};
