// import Vue from 'vue'
import VueRouter from 'vue-router'
import {objToQueryString} from "../config/util";

Vue.use(VueRouter);

const routes = [
    {
      path: '/gzhMsgPay',
      name: 'gzhMsgPay',
      component: () => import('../views/gzhMsgPay.vue'),
      meta: {name: '支付'}
    },
    {
      path: '/updateBindWx',
      name: 'updateBindWx',
      component: () => import('../views/login/updateBindWx.vue'),
      meta: {name: '绑定微信'}
    },
    {
      path: '/wx',
      name: 'wx',
      component: () => import('../wx.vue'),
      meta: {name: '微信验证'}
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/layout.vue'),
      children: [
        {
          path: 'bindTel',
          name: 'bindTel',
          component: () => import(/* webpackChunkName: "about" */ '../views/login/bindTel.vue'),
          meta: {name: '绑定手机'}
        },
        {
          path: 'wechat',
          name: 'wechat',
          component: () => import(/* webpackChunkName: "about" */ '../views/login/wechat.vue'),
          meta: {name: '微信登录'}
        },
      ]
    },
    {
      path: '/home',
      redirect: '/'
    },
    {
      path: '/',
      name: 'app',
      // redirect: '/home',
      component: () => import('../views/layout.vue'),
      children: [
        {
          path: '/',
          name: 'index',
          component: () => import(/* webpackChunkName: "about" */ '../views/tabbar.vue'),
          // redirect: '/discover',
          children: [
            {
              path: '',
              name: 'homeLayout',
              component: () => import('../views/home/homeLayout.vue'),
              children: [
                {
                  path: '/',
                  name: 'home',
                  meta: {
                    name: '看方案',
                    keepAlive: true,
                    pcPath(to) {
                      const url = '/'
                      const queryString = objToQueryString({
                        ...to.query,
                        tab: ''
                      })
                      return url + '?' + queryString
                    }
                  },
                  component: () => import('../views/home/index.vue')
                },
                {
                  name: 'hotRank',
                  path: '/hotRank',
                  component: () => import('../views/home/components/homeRank.vue'),
                  meta: {
                    name: '热榜',
                    keepAlive: true,
                  }
                },
                {
                  name: 'ai',
                  path: '/ai',
                  component: () => import('../views/home/components/homeAiChat.vue'),
                  meta: {
                    name: '聊天',
                    keepAlive: true,
                    pcPath(to) {
                      return '/ai/chat/' + to.query.id
                    }
                  }
                }
              ]
            },
            {
              path: '/resource',
              name: 'resource',
              meta: {
                name: '群内商机',
                auth: true,
                pcPath: '/needs'
              },
              component: () => import('../views/resource/index.vue')
            },
            {
              path: '/planList',
              name: 'planList',
              meta: {
                keepAlive: true,
                name: '合集',
                auth: true,
                pcPath: '/collection'
              },
              component: () => import('../views/home/components/homeCollections.vue')
            },
            {
              path: '/task',
              name: 'task',
              meta: {
                name: '赚取严值',
                auth: true,
                pcPath(to) {
                  return '/center/yanzhi/task'
                }
              },
              component: _ => import('../views/personal/task.vue')
            },
            {
              path: '/mine',
              name: 'mine',
              meta: {
                name: '个人中心',
                keepAlive: true,
                auth: true,
                pcPath: '/my/plan'
              },
              // component: () => import('../views/mine/index.vue')
              component: _ => import('../views/personal/index.vue')
            },
            {
              path: '/rank',
              name: 'rank',
              component: () => import('../views/rank/index.vue'),
              meta: {
                name: '用户排行榜单',
                auth: true,
                pcPath: '/rank/user/browse'
              }
            },
            {
              path: '/userLevel',
              name: 'userLevel',
              component: () => import('../views/rank/userLevel.vue'),
              meta: {
                name: '用户等级规则',
                auth: false,
                pcPath: '/center/yanzhi/level'
              }
            }
          ]
        },
        {
          path: '/home/sharePost',
          name: 'homeSharePost',
          component: () => import(`../views/home/homeSharePost.vue`)
        },
        {
          path: '/otherPerson/:id',
          name: 'otherPerson',
          meta: {
            name: 'TA的主页',
            // auth: true,
            pcPath(to) {
              return `/user/${to.params.id}`;
            },
            keepAlive: true
          },
          component: () => import('../views/personal/otherPerson.vue')
        },

        {
          path: '/caseDetail/sharePost/:id',
          name: 'caseDetailSharePost',
          meta: {
            // name: '方案详情',
            // auth: true,
          },
          component: _ => import('../views/discover/detailSharePost.vue')
        },
        {
          path: '/downloadPlan',
          name: 'downloadPlan',
          meta: {
            pcPath(to) {
              return `/plan/download?id=` + to.query.id + `&visitor=` + to.query.visitor
            }
          },
          component: _ => import('../views/discover/download/plan.vue')
        },
        {
          path: '/downloadPlanList',
          name: 'downloadPlanList',
          meta: {
            pcPath(to) {
              return `/collection/download?id=` + to.query.id + `&visitor=` + to.query.visitor
            }
          },
          component: _ => import('../views/discover/download/planList.vue')
        },

        {
          path: '/caseDetail/:id',
          name: 'caseDetail',
          meta: {
            // name: '方案详情',
            // auth: true,
            pcPath(to) {
              return '/plan/' + to.params.id;
            },
          },
          component: _ => import('../views/discover/detail/index.vue')
        },

        {
          path: '/casePlanDetail/:id',
          name: 'casePlanDetail',
          meta: {
            name: '方案合集详情',
            // auth:true,
            pcPath(to) {
              return '/collection/plans?id=' + to.params.id;
            }
          },
          component: _ => import('../views/discover/planList.vue')
        },
        {
          path: '/caseGroup/:id',
          name: 'caseGroup',
          meta: {
            name: '方案合集',
            pcPath(to) {
              return '/collection/' + to.params.id;
            }
          },
          component: _ => import('../views/discover/caseGroup.vue')
        },

        {
          path: '/preview/:id',
          name: 'preview',
          meta: {
            name: '查看图片'
          },
          component: _ => import('../views/discover/preview.vue')
        },

        {
          path: '/information',
          name: 'information',
          meta: {
            name: '我的主页',
            auth: true,
            pcPath: '/my/works/plan',
            keepAlive: true
          },
          // component: _ => import('../views/personal/index.vue')
          component: () => import('../views/mine/index.vue')
        },
        {
          path: '/shop',
          name: 'shop',
          component: () => import ('../views/shop/index'),
          meta: {
            name: '严值商城',
            auth: false,
            pcPath: '/shop',
            keepAlive: true
          },
        },
        {
          path: '/shop/goods/:id',
          name: 'goodsDetail',
          component: () => import('../views/shop/goods'),
          meta: {
            name: '商品详情',
            auth: false,
            pcPath(to) {
              return `/shop/goods/${to.params.id}`
            },
            keepAlive: false
          }
        },
        {
          path: '/shop/createOrder/:id',
          name: 'createOrder',
          component: () => import('../views/shop/createOrder'),
          meta: {
            name: '创建订单',
            auth: false,
            pcPath(to) {
              return `/shop/order/create/${to.params.id}`
            },
            keepAlive: false
          }
        },
        {
          path: '/shop/order',
          name: 'order',
          component: () => import('../views/shop/order'),
          meta: {
            name: '订单列表',
            auth: false,
            pcPath(to) {
              return `/shop/order`
            },
            keepAlive: true
          },
        },
        {
          path: '/shop/play/:id',
          name: 'play',
          component: () => import('../views/shop/play'),
          meta: {
            name: '课程详情',
            auth: false,
            pcPath(to) {
              return `/course/play/${to.params.id}`
            },
            keepAlive: true
          },
        },
        {
          path: '/planCoupon',
          name: 'planCoupon',
          component: () => import('../views/discover/planCoupon'),
          meta: {
            pcPath: '/planCoupon'
          }
        },
        {
          path: '/shop/order/:orderNo',
          name: 'orderDetail',
          component: () => import('../views/shop/orderDetail'),
          meta: {
            name: '订单详情',
            auth: false,
            pcPath(to) {
              return `/shop/order/${to.params.orderNo}`
            },
            keepAlive: false
          },
        },
        {
          path: '/resource/publish',
          name: 'resourcePublish',
          component: () => import('../views/resource/publish/index.vue'),
          meta: {
            name: '发布商机',
            auth: true,
            pcPath(to) {
              return `/needs?showWx=true`
            },
            keepAlive: false
          },
        },
        {
          path: '/resource/collect',
          name: 'resourceCollect',
          component: () => import('../views/resource/collect/index.vue'),
          meta: {
            name: '我的收藏',
            auth: true,
            pcPath(to) {
              return `/needs?showWx=true`
            },
            keepAlive: false
          },
        },

        // {
        //   path: '/task',
        //   name: 'task',
        //   meta: {
        //     name: '赚取严值',
        //     auth: true,
        //     pcPath(to){
        //       if(to.query.inviteCodeVisible === 'true'){
        //         return '/set/earnYanZhi?index=2&inviteCodeVisible=true';
        //       }
        //       return '/set/earnYanZhi?index=2';
        //     }
        //   },
        //   component: _ => import('../views/personal/task.vue')
        // },
        {
          path: '/discover',
          name: 'discover',
          meta: {
            name: '找方案',
            keepAlive: true
          },
          component: _ => import('../views/discover/index.vue')
        },
        {
          path: '/old_message',
          name: 'message',
          meta: {
            name: '我的消息',
            auth: true,
            pcPath: '/center/message'
          },
          component: _ => import('../views/personal/message.vue')
        },
        {
          path: '/message',
          name: 'message',
          meta: {
            name: '我的消息',
            auth: true,
            pcPath: '/center/message',
            keepAlive: true
          },
          component: _ => import('../views/personal/message/index.vue')
        },
        {
          path: '/collection',
          name: 'collection',
          meta: {
            name: '我的收藏',
            auth: true,
            keepAlive: true,
            pcPath: '/center/collection/plan'
          },
          component: _ => import('../views/personal/collection.vue')
        },
        {
          path: '/unlock',
          name: 'unlock',
          meta: {
            name: '我的解锁',
            auth: true,
            keepAlive: true,
            pcPath: '/center/unlock/plan'
          },
          component: _ => import('../views/personal/unlock.vue')
        },
        {
          path: '/member',
          name: 'member',
          meta: {
            name: '「灵感严选」会员',
            pcPath(to) {
              let path = '/vip';
              const queries = []
              if (to.query.id) {
                queries.push('id=' + to.query.id)
              }
              if (to.query.encyclopedia === 'true') {
                queries.push('encyclopedia=true')
              }
              if (queries.length > 0) {
                path += '?' + queries.join('&')
              }
              return path;
            }
          },
          component: _ => import('../views/personal/member.vue')
        },
        {
          path: '/editInfo',
          name: 'editInfo',
          meta: {
            name: '编辑资料',
            auth: true,
            pcPath: '/center/account/data'
          },
          component: _ => import('../views/personal/editInfo.vue')
        },
        {
          path: '/wallet',
          name: 'wallet',
          meta: {
            name: '我的钱包',
            auth: true,
            pcPath: '/center/wallet/profit'
          },
          component: _ => import('../views/personal/wallet.vue')
        },
        {
          path: '/pickUpMoney',
          name: 'pickUpMoney',
          meta: {
            name: '严值提现',
            auth: true,
            // pcPath: '/set/wallet?index=6'
          },
          component: _ => import('../views/personal/pickUpMoney.vue')
        },
        {
          path: '/topUpMoney',
          name: 'topUpMoney',
          meta: {
            name: '严值充值',
            auth: true,
            pcPath: '/center/wallet/recharge'
          },
          component: _ => import('../views/personal/topUpMoney.vue')
        },
        {
          path: '/modelAll',
          name: 'modelAll',
          meta: {
            name: '弹出框'
          },
          component: _ => import('../views/discover/model.vue')
        },

        {
          path: '/bindAccount',
          name: 'bindAccount',
          meta: {
            name: '账号安全',
            auth: true,
            pcPath: '/center/account/security'
          },
          component: _ => import('../views/personal/bindAccount.vue')
        },
        {
          path: '/identityCertify',
          name: 'identityCertify',
          meta: {
            name: '认证身份',
            auth: true,
          },
          component: _ => import('../views/personal/identityCertify.vue')
        },

        {
          path: '/userPraise',
          name: 'userPraise',
          meta: {
            name: '真实评价',
            // auth: true,
            pcPath: '/about/praise',
          },
          component: _ => import('../views/personal/userPraise.vue')
        },

        {
          path: '/browseRecord',
          name: 'browseRecord',
          meta: {
            name: '我的足迹',
            auth: true,
          },
          component: _ => import('../views/personal/browseRecord.vue')
        },

        {
          path: '/publish',
          name: 'publish',
          meta: {
            name: '我的发布',
            auth: true,
          },
          component: _ => import('../views/personal/publish.vue')
        },

        {
          path: '/fansAndFocus',
          name: 'fansAndFocus',
          meta: {
            name: '粉丝&关注',
            auth: true,
            pcPath: '/user'
          },
          component: _ => import('../views/personal/fansAndFocus.vue')
        },

        // {
        //   path: '/record',
        //   name: 'record',
        //   meta: {
        //     name: '查看记录',
        //     auth: true,
        //     pcPath: '/set/wallet?index=6&currIndex=1'
        //   },
        //   component: _ => import('../views/personal/record.vue')
        // },
        {
          path: '/record',
          name: 'record',
          meta: {
            name: '查看记录',
            auth: true,
            pcPath: '/center/wallet/record/award'
          },
          component: _ => import('../views/personal/record2.vue')
        },

        {
          path: '/exchange',
          name: 'exchange',
          meta: {
            name: '「灵感严选」会员兑换',
            auth: true,
            pcPath: '/vip'
          },
          component: _ => import('../views/personal/exchange.vue')
        },

        {
          path: '/result/alipay',
          name: 'exchange',
          meta: {
            name: '支付结果',
            auth: true
          },
          component: _ => import('../views/result/index.vue')
        },
        {
          path: '/result/wechat/:order',
          name: 'exchange',
          meta: {
            name: '支付结果',
            auth: true
          },
          component: _ => import('../views/result/wechat.vue')
        },
        {
          path: '/about',
          name: 'about',
          meta: {
            name: '关于',
            auth: false,
            pcPath: '/about/us'
          },
          component: _ => import('../views/about/index.vue')
        },
        {
          path: '/about/us',
          name: 'aboutUs',
          meta: {
            name: '关于我们',
            auth: false,
            pcPath: '/about/us'
          },
          component: _ => import('../views/about/about.vue')
        },
        {
          path: '/about/copyright',
          name: 'copyright',
          meta: {
            name: '版权声明',
            auth: false,
            pcPath: '/about/copyright/aim'
          },
          component: _ => import('../views/about/copyright.vue')
        },
        {
          path: '/about/agreement',
          name: 'agreement',
          meta: {
            name: '用户协议',
            auth: false,
            pcPath: '/about/deal/user'
          },
          component: _ => import('../views/about/agreement.vue')
        },
        {
          path: '/about/upload',
          name: 'aboutUpload',
          meta: {
            name: '上传协议',
            auth: false,
            pcPath: '/about/deal/upload'
          },
          component: _ => import('../views/about/agreement.vue')
        },
        {
          path: '/about/vip',
          name: 'aboutVip',
          meta: {
            name: '会员条款',
            auth: false,
            pcPath: '/about/deal/vip'
          },
          component: _ => import('../views/about/agreement.vue')
        },
        {
          path: '/about/question',
          name: 'agreement',
          meta: {
            name: '常见问题',
            auth: false,
            pcPath: '/about/qa'
          },
          component: _ => import('../views/about/question.vue')
        },
        {
          path: '/about/standard',
          name: 'standard',
          meta: {
            name: '上传规范',
            auth: false,
            pcPath: '/about/qa/rule'
          },
          component: _ => import('../views/about/standard.vue')
        },
        {
          path: '/wxGroup',
          name: 'wxGroup',
          meta: {
            name: '社群矩阵',
            auth: false,
            pcPath: '/wxGroup'
          },
          component: _ => import(`../views/wxGroup/index.vue`)
        },
        {
          path: '/club',
          name: 'club',
          meta: {
            name: '加入灵感严选俱乐部~',
            auth: false,
            pcPath: '/club'
          },
          component: _ => import('../views/club/index.vue')
        },
        {
          path: '/clubDiscount',
          name: 'clubDiscount',
          meta: {
            name: '加入灵感严选俱乐部~',
            auth: false,
            pcPath: '/club'
          },
          component: _ => import('../views/club/discountPay.vue')
        },
        {
          path: '/redirect',
          name: 'redirect',
          component: () => import('../views/redirect.vue'),
          meta: {
            auth: false,
            pcPath: '/redirect'
          }
        }
      ]
    },

  ]
;


export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


