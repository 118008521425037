import {user} from '@/api'
import {userV2Api} from "../api/v2/userV2Api";

export const share = {
  namespaced: true,

  state: {
    hasInit: false,
    inviteData: {
      inviteNum: 6,
      inviteYanzhi: 300,
      inviteUsers: []
    },
  },


  mutations: {

    SET_INVITE_DATA(state, inviteData) {
      state.inviteData = inviteData
    }
  },


  actions: {

    async loadInviteData({commit, state, rootState}, refresh) {
      const {userInfo} = rootState
      if (!userInfo || !Object.keys(userInfo).length) {
        return
      }

      if (refresh || !state.hasInit) {
        state.hasInit = true
        try {
          const data = await userV2Api.findMyInviteData()
          // const {data} = await user.invite()
          commit('SET_INVITE_DATA', data)
        } catch (e) {
          console.error(e)
          state.hasInit = false
        }
      }

    }


  }

}
