import {userRankV2Api} from "../api/v2/userRankV2Api";
import {debounce} from "../config/decorators/debounce";
import {creatorRankV2Api} from "../api/v2/creatorRankV2Api";
import Vue from 'vue'

const rankInfoClass = {
  avatar: '',
  beatRate: 0,
  city: '',
  count: 0,
  hasThumb: false,
  nickName: '',
  plans: [],
  profession: '',
  rankIndex: 1,
  thumbCount: 0,
  userId: '',
  userLevel: ''
}

export const rankPage = {
  namespaced: true,
  state: {
    header: {
      tab: '',    // user/creator/task
      deep: true
    },
    query: {
      userRank: {
        daysInRange: 7,
        daysInRangeText: '近七日'
      },
      creatorRank: {
        issueId: '',
        issueText: ''
      }
    },
    options: {
      userRankDaysInRange: [
        {text: '近七日', value: 7},
        {text: '近一月', value: 30},
        {text: '近一年', value: 365},
      ]
    },
    tab: {
      userRank: 'award',
      userRankFixed: false,
      creatorRank: 5,
      creatorRankFixed: false,
    },
    userRank: {
      browse: {
        loading: false,
        apiName: 'rankByBrowseTimes',
        pageData: {
          current: 0,
          size: 10,
          records: [],
          total: 100
        }
      },
      collect: {
        loading: false,
        apiName: 'rankByCollectTimes',
        pageData: {
          current: 0,
          size: 10,
          records: [],
          total: 100
        }
      },
      recommend: {
        loading: false,
        apiName: 'rankByRecommendTimes',
        pageData: {
          current: 0,
          size: 10,
          records: [],
          total: 100
        }
      },
      unlock: {
        loading: false,
        apiName: 'rankByUnlockTimes',
        pageData: {
          current: 0,
          size: 10,
          records: [],
          total: 100
        }
      },
      award: {
        loading: false,
        apiName: 'rankByAward',
        pageData: {
          current: 0,
          size: 10,
          records: [],
          total: 100
        }
      }
    },
    creatorRank: {
      issues: [],
      records: []
    },
    myRankInfo: {
      currPoint: 0,
      hasNextLevel: '',
      level: '',
      nextLevelMinPoint: 0,
      awardRank: {...rankInfoClass},
      browseRank: {...rankInfoClass},
      collectRank: {...rankInfoClass},
      recommendRank: {...rankInfoClass},
      unlockRank: {...rankInfoClass},
    }
  },


  mutations: {

    SET_MY_RANK_INFO(state, myRankInfo) {
      state.myRankInfo = myRankInfo
    },


    SET_HEADER_TAB(state, tab) {
      state.header.tab = tab
    },


    SET_QUERY_USER_RANK(state, query) {
      state.query.userRank = query
    },


    SET_USER_RANK(state, {rank, rankName}) {
      state.userRank[rankName] = rank
    },


    SET_TAB_USER_RANK(state, tab) {
      state.tab.userRank = tab
    },


    SET_HEADER_DEEP(state, deep) {
      state.header.deep = deep
    },


    SET_TAB_USER_RANK_FIXED(state, tabUserRankFixed) {
      state.tab.userRankFixed = tabUserRankFixed
    },


    SET_CREATOR_RANK_ISSUES(state, issues) {
      state.creatorRank.issues = issues
    },


    SET_QUERY_CREATOR_RANK(state, query) {
      state.query.creatorRank = query
    },


    SET_TAB_CREATOR_RANK(state, tab) {
      state.tab.creatorRank = tab
    },


    SET_TAB_CREATOR_RANK_FIXED(state, fixed) {
      state.tab.creatorRankFixed = fixed
    },


    SET_CREATOR_RANK_RECORDS(state, records) {
      state.creatorRank.records = records
    },


    CLEAR_USER_RANK(state) {
      for (const key in state.userRank) {
        state.userRank[key].pageData.records = []
        state.userRank[key].pageData.current = 0
        state.userRank[key].pageData.total = 100
      }
    }

  },


  actions: {

    // 加载我的排行榜信息
    async loadMyRankInfo({state, commit}) {
      const query = state.query.userRank
      const res = await userRankV2Api.loadMy(query)
      commit('SET_MY_RANK_INFO', res)
    },


    // 切换顶部栏
    async switchHeaderTab({state, commit, dispatch}, tab) {
      commit('SET_HEADER_TAB', tab)
      dispatch('computeDeep')
      history.replaceState(null, null, '/rank?nav=' + tab)
    },


    async switchUserRankTab({state, commit, dispatch}, tab) {
      commit('SET_TAB_USER_RANK', tab)
      const rank = state.userRank[tab]
      const {pageData} = rank
      if (pageData.current === 0) {
        dispatch('loadMoreUserRank', {rankName: tab})
      }
    },


    // 切换用户榜单日期
    async selectUserRankDaysInRange({state, commit, dispatch}, value) {
      const option = state.options.userRankDaysInRange.find(o => o.value === value)
      if (option) {
        commit('SET_QUERY_USER_RANK', {
          ...state.query.userRank,
          daysInRange: option.value,
          daysInRangeText: option.text
        })
      }
      commit('CLEAR_USER_RANK')
      dispatch('loadMoreUserRank', {rankName: state.tab.userRank})
      dispatch('loadMyRankInfo')
    },


    // 用户榜单加载更多
    loadMoreUserRank: debounce(async ({state, commit}, {rankName}) => {
      const rank = state.userRank[rankName]
      const {pageData} = rank
      if (rank.loading) {
        return
      }

      if (pageData.records.length >= pageData.total) {
        return
      }

      try {
        // rank.laoding = true
        commit('SET_USER_RANK', {rankName, rank: {...rank, loading: true}})
        const query = {
          ...state.query.userRank,
          ...pageData,
          current: pageData.current + 1,
          pageSize: pageData.size
        }
        const res = await userRankV2Api[rank.apiName](query)
        rank.pageData.current = res.current
        rank.pageData.records.push(...res.records)
        rank.pageData.total = res.records.length
        commit('SET_USER_RANK', {rankName, rank})
      } finally {
        rank.loading = false
        commit('SET_USER_RANK', {rankName, rank})
      }
    }),


    computeDeep({commit, state}) {
      let deep = false
      if (state.header.tab === 'user' && !state.tab.userRankFixed) {
        deep = true
      }
      if (state.header.tab === 'creator' && !state.tab.creatorRankFixed) {
        deep = true
      }
      commit('SET_HEADER_DEEP', deep)
    },


    setTabUserRankFixed({state, dispatch, commit}, userRankFixed) {
      commit('SET_TAB_USER_RANK_FIXED', userRankFixed)
      dispatch('computeDeep')
    },


    async loadCreatorRankIssues({commit}) {
      let creatorRankIssues = await creatorRankV2Api.findIssueByType(1);
      let issueIndex = creatorRankIssues.length;
      for (const issue of creatorRankIssues) {
        const beginTime = issue.beginTime.slice(0, 10);
        // const endTime=issue.endTime.slice(0,10);
        issue.text = beginTime + `\r\n第${issue.issue}期`;
        issue.value = issue.id;
        issueIndex--;
      }
      creatorRankIssues = [
        {
          value: '',
          text: '总榜',
          issueText: '总榜',
          issueBeginTime: '',
          issueEndTime: ''
        },
        ...creatorRankIssues
      ];
      commit('SET_CREATOR_RANK_ISSUES', creatorRankIssues)
    },


    async selectCreatorRankIssue({commit, state, dispatch}, issueId) {
      const issue = state.creatorRank.issues.find(v => v.value === issueId)
      commit('SET_QUERY_CREATOR_RANK', {
        ...state.query.creatorRank,
        issueId,
        issueText: issueId ? issue.text.slice(5) : issue.issueText
      })
      dispatch('loadCreatorRankRecords')
    },


    async loadCreatorRankRecords({commit, state}) {
      const issues = state.creatorRank.issues
      const issueId = state.query.creatorRank.issueId
      const type = state.tab.creatorRank
      const issueItem = issues.find(i => i.value === issueId)
      const beginTime = issueItem ? issueItem.beginTime : ''
      const endTime = issueItem ? issueItem.endTime : ''
      const res = await creatorRankV2Api.findByBeginTimeAndEndTimeAndType({
        type, beginTime, endTime
      })
      commit('SET_CREATOR_RANK_RECORDS', res)
    },


    async switchCreatorRankTab({commit, dispatch}, tab) {
      commit('SET_TAB_CREATOR_RANK', tab)
      dispatch('loadCreatorRankRecords')
    },


    setTabCreatorRankFixed({commit, dispatch}, fixed) {
      commit('SET_TAB_CREATOR_RANK_FIXED', fixed)
      dispatch('computeDeep')
    },

  },

}
