// import Vue from "vue"; // 引入 Vue 是因为要用到 Vue.extend() 这个方法
import Main from "./toast.vue"; // 引入刚才的 toast 组件

let ToastConstructor = Vue.extend(Main); // 这个在前面的前置知识内容里面有讲到
let instance;

const Toast = function(data) {
    if(document.querySelector('.toast')) return;
    instance = new ToastConstructor({
        data
    }); // 渲染组件
    document.body.appendChild(instance.$mount().$el); // 挂载到 body 下
};

["success", "error",'info', 'text'].forEach(type => {
    Toast[type] = (content,desc) => {
        return Toast({content, desc,type});
    };
});

export default Toast;
