import {v2Request} from "./v2Request";


export const walletApiV2 = {

  findMy(){
    return v2Request.get('/search-svr/userWallet/findMy')
  }

}
