<template>
    <div class="alert-overlay" :class="show&&'show'">
        <div class="alert" :class="show?'bounce':'hide'">
            <div class="alert-header">
                <span>{{title}}</span>
            </div>
            <div class="alert-body">
                <span>
                    {{content}}
                </span>
            </div>
            <div class="alert-footer">
                <a v-if="showCancel" href="javascript: void (0)" class="btn btn-cancel" @click="close">{{cancelText}}</a>
                <a href="javascript: void (0)" class="btn btn-confirm" :class="!showCancel&&'leftRadius'" @click="submit">确认</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "alert",
        data(){
          return{
              show: false,
              cancelText: '关闭'
          }
        },
        created(){
            setTimeout(_ => {
                this.show = true;
            })
        }
    }
</script>

<style scoped lang="less">
    .alert-overlay{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        background-color: rgba(0,0,0,0);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .15s ease;
        &.show{
            background-color: rgba(0,0,0,.5);
        }
        .alert{
            background-color: #fff;
            width: 85%;
            border-radius: @card-radius;
            transition: opacity .3s ease;
            &.hide{
                opacity: 0;
            }
            &-header{
                border-bottom: 1px solid @border-color;
                padding: @padding;
                position: relative;
                span{
                    font-size: @large;
                }
            }
            &-body{
                padding: @padding;
                span{
                    color: #666;
                    font-size: @md;
                }
            }
            &-footer{
                display: flex;
                a{
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: @padding;
                    &.btn-confirm{
                        background-color: @theme-color;
                        border-top: 1px solid @theme-color;
                        color: #fff;
                        border-bottom-right-radius: @card-radius;
                        &:active{
                            opacity: .9;
                        }
                        &.leftRadius{
                            border-bottom-left-radius: @card-radius;
                        }
                    }
                    &.btn-cancel{
                        border-top: 1px solid @border-color;
                        border-bottom-left-radius: @card-radius;
                        &:active{
                            background-color: #ccc;
                        }
                    }
                }
            }
        }
    }

    .bounce{
        animation:bounceIn .4s ease;
    }

    @keyframes bounceIn {
        0% {
            opacity:0;
            transform:scale(.3)
        }
        50% {
            opacity:1;
            transform:scale(1.05)
        }
        70% {
            transform:scale(.9)
        }
        100% {
            transform:scale(1)
        }
    }
</style>
