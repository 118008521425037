import {v2Request} from "./v2Request";

let _findFirstLoginTaskProm = null

export const taskV2Api = {

  //  根据id查询
  async findById(id) {
    return v2Request.get(`search-svr/task/findById`, {params: {id}})
  },


  async findMyProfitPage(params) {
    return v2Request.get(`search-svr/userWallet/findMyProfitPage`,{params})
  },


  async findMyAwardPage(params) {
    return v2Request.get(`search-svr/userWallet/findMyAwardPage`,{params})
  },


  async findAll(){
    return v2Request.get(`search-svr/task/findAll`)
  },


  async findFirstLoginTask(){
    if (!_findFirstLoginTaskProm) {
      _findFirstLoginTaskProm = v2Request.get(`search-svr/task/findById`, {params: {id: 1}})
    }
    return _findFirstLoginTaskProm;
  }

};
