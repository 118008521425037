import {
  get,
  post,
  remove,
  upload,
  put
} from './api.config';
import {walletApiV2} from "./v2/walletApiV2";
import {v2Request} from "./v2/v2Request";

export const loginApi = (params) => get(`/applet/login`, params);

export const logoutApi = _ => get(`/applet/logout`, _);

export const userApi = {

  getQRCode: _ => get('/wx/sv/sv/message/getQRCodeTicket?loginType=H5'),             //获取二维码

  ifScan: scanRecordId => get('/wx/sv/sv/message/scanSearch?scanRecordId=' + scanRecordId),             //是否扫描

  h5Login: p => get(`/wx/sv/sv/message/login?code=${p.code}&shareUserId=${p.shareUserId}`),             //是否扫描

  invite: _ => get('/applet/invite/record/getInviteList'),             //邀请记录

  getTicket: _ => get(`/wx/sv/sv/message/getJsApiTicket`),            //获取ticket

  jsApiOrder: params => get(`/wx/jsapi/pay/unifiedOrder`, {...{appId: process.env.VUE_APP_APPID}, ...params}),  //获取支付订单 jsapi

  payOrder: params => get(`/wx/h5/pay/unifiedOrder`, {
    ...{
      appId: process.env.VUE_APP_APPID,
      paySourceType: 'Wap'
    }, ...params
  }),         //获取支付订单 h5

  getH5Order: tradeRecordId => get(`/wx/h5/pay/getH5Order?tradeRecordId=${tradeRecordId}`),           //获取h5订单是否支付

  getJsApiOrder: tradeRecordId => get(`/wx/jsapi/pay/orderQuery?tradeRecordId=${tradeRecordId}`),     //获取jsapi订单是否支付


  aliPayOrder: params => get(`/alipay/mobile/pay/mobileOrderTrade`, params),               //支付宝下单

  aliPayQuery: params => get(`/alipay/pay/orderQuery`, params),               //支付宝查询是否已经支付

  ifFocus: params => get(`/applet/plan/focus/isFocusOther?myUserId=${params.userId}&otherUserId=${params.otherUserId}`),                 //是否关注某人


  getPhoneCode: mobile => get('/web/common/sms/sendValidateCode', {mobile}),

  upload: file => upload('/web/common/resource/upload', file),            //上传

  logout: _ => get(`/applet/logout`, _),           //退出登录

  info: async id => {
    const data = await v2Request.get('/search-svr/user/findUserDetail', {params: {id}})
    return {
      code: 1, data
    }
  },  //用户信息

  userInfo: async id => {
    const data = await v2Request.get('/search-svr/user/findUserDetail', {params: {id}})
    return {
      code: 1, data
    }
  },

  emailCode: email => get(`/applet/email/sendValidateCode`, {email}),    //发送邮箱验证码

  email: params => put(`/applet/email/updateUserEmail?email=${params.email}&emailValidateCode=${params.emailValidateCode}`, params),           //更新用户邮箱

  changeMobile: params => put(`/applet/user/updateUserMobile?newMobile=${params.newMobile}&newValidateCode=${params.newValidateCode}&oldMobile=${params.oldMobile}&oldValidateCode=${params.oldValidateCode}`),           //更新用户手机号

  changePassword: p => put(`/applet/user/updateUserPassword?confirmPassword=${p.confirmPassword}&mobile=${p.mobile}&newPassword=${p.newPassword}&validateCode=${p.validateCode}`),            //修改密码

  taskAll: _ => get(`/applet/yanZhiTask/findAll`),                        //任务

  taskStatus: userId => get(`/applet/yanZhiTask/getUserYanzhi`, {userId}),                           //任务完成情况

  // myUpload: params => get(`applet/user/findMyProjPlanListByPage`, params), //我的上传

  myRecommend: params => get(`/applet/user/findMyRecommendProjPlanListByPage`, params),  //我的推荐方案

  myRecommendList: params => get(`/applet/plan/list/recommend/getListByPage`, params),  //我的推荐方案合集

  myFocus: params => get(`/applet/user/findFocusListByPage`, params),             //我的关注

  myFans: params => get(`/applet/user/findFansListByPage`, params),

  collectList: params => get(`/applet/plan/collect/getListByPage`, params),//收藏的方案

  collectPlanList: params => get(`/applet/plan/list/collect/getListByPage`, params),//收藏的合集

  // wallet: params => get(`/applet/user/wallet/getByUserId`),    //钱包
  async wallet() {
    const data = await walletApiV2.findMy()
    return {
      code: 1,
      msg: '操作成功',
      data
    }
  },

  memberInfo: async _ => {
    const data = await get('/applet/v2/search-svr/vipRight/findCurrValidVip')
    return {
      code: 1,
      msg: '操作成功',
      data
    }
  },   //会员信息

  member: _ => get(`/applet/vip/right/getList`),  //会员权益

  exchange: changeSendRecordId => put(`/applet/changeSendRecord/active?changeSendRecordId=${changeSendRecordId}`),  //兑换会员

  unlockGroup: params => get(`/applet/plan/list/pay/getListByPage`, params),                              //获取解锁方案合集列表

  unlock: params => get(`/applet/plan/pay/getListByPage`, params),                              //获取解锁方案列表

  downloadGroup: params => get(`/applet/plan/list/download/getListByPage`, params),

  cancelFocus: id => remove(`/applet/plan/focus/cancel`, {toUserId: id}),                     //取消关注

  focus: params => post(`/applet/plan/focus/add`, params),                     //关注

  awardMsg: params => get(`/applet/user/message/getAwardListByPage`, params),         //奖励消息

  systemMsg: async params => {
    // return get(`/applet/user/message/getMyMessageListByPage`, params)
    const res = await v2Request.get(`/search-svr/message/findMyMessagePage`, {
      params: {
        ...params, pageSize: params.size || params.pageSize
      }
    })
    res.size = res.pageSize || res.size
    res.pageSize = res.pageSize || res.size
    return res;
  },         //系统消息

  ProfitMsg: params => get(`/applet/user/message/getProfitListByPage`, params),         //收益消息


  cashOut: params => get(`/applet/user/cash/out/record/getListByPage`, params),         //提现记录

  recharge: params => get(`/applet/user/recharge/record/getListByPage`, params),         //充值记录
  recharge2: params => get(`/applet/user/recharge/record/getListByPage2`, params),         //充值记录

  profit: ({current, size}) => get(`/applet/profit/getListByPage?current=${current}&size=${size}`),  // 收益记录
  profit2: ({current, size}) => get(`/applet/profit/getListByPage2?current=${current}&size=${size}`),  // 收益记录

  taskAward: ({current, size}) => get(`/applet/profit/task/getListByPage?current=${current}&size=${size}`),  // 任务记录
  taskAward2: ({current, size}) => get(`/applet/profit/task/getListByPage2?current=${current}&size=${size}`),  // 任务记录

  consume: ({current, size}) => get(`/applet/user/consume/record/getListByPage?current=${current}&size=${size}`),  // 消费记录
  consume2: ({
               current,
               size,
               type
             }) => get(`/applet/user/consume/record/getListByPage2?current=${current}&size=${size}${type ? '&type=' + type : ''}`),  // 消费记录

  changeCover: titleResourceId => put(`/applet/user/updateTitleResource?titleResourceId=${titleResourceId}`),         //修改主页封面

  loginByJsCode: (jsCode, shareUserId, sharePlanId) => get(`/wx/sv/sv/message/login`, {
    code: jsCode,
    shareUserId,
    sharePlanId
  }),         //通过微信code登录

  bindMobile: p => put(`/applet/user/updateUserMobileForNew?mobile=${p.mobile}&validateCode=${p.validateCode}&appletBind=true`),         //绑定手机号

  readMessage: params => put(`/applet/user/message/updateMessageReadByMessageType`, params),         //全部已读消息

  ifRead: _ => get(`/applet/user/message/getMyMessageByNoRead`),

  about: params => get(`/web/about/loadByCodes?${params}`),                      //关于

  question: _ => get(`/web/question/selectQuestions`),

  getFirstLoginSuccessAward: () => get(`/web/publicGet/info/getFirstLoginSuccessAward`),
  findBrowseListByPage: ({
                           current = 1,
                           size = 10
                         }) => get(`/web/plan/browse/findBrowseListByPage?current=${current}&size=${size}`),

};
