/*
   自定义 loading 组件
   调用
   this.$loading('正在加载中...');
   this.$loading.close();
*/
let _LOADING = {
    show: false,     // Boolean loading显示状态
    component: null  // Object loading组件
};
export default {
    install(Vue) {
        /*
            text: String
            type: String
        */
        Vue.prototype.$loading = function (text = '正在加载中...', type) {
            if (type == 'close') {
                _LOADING.component.show = _LOADING.show = false;
            } else {
                if (_LOADING.show) {
                    return;
                }
                let LoadingCompoent = Vue.extend({
                    data: function () {
                        return {
                            show: _LOADING.show
                        }
                    },
                    template: `<div class="v-loading-bg" v-if="show">
                                    <div class="v-loading">
                                        <div class="loading-svg">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                 viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                                                <path d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                                                    <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25"
                                                              dur="0.6s" repeatCount="indefinite"></animateTransform>
                                                </path>
                                            </svg>
                                           
                                        </div>
                                        <span>${text}</span>
                                    </div>
                                </div>`
                });
                _LOADING.component = new LoadingCompoent();
                let element = _LOADING.component.$mount().$el;
                document.body.appendChild(element);
                _LOADING.component.show = _LOADING.show = true;
            }
        };
        // 打开/关闭
        ['open', 'close'].forEach(function (type) {
            Vue.prototype.$loading[type] = function (text) {
                return Vue.prototype.$loading(text, type);
            }
        });
    }
}
