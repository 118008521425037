// 初始化百度统计
export function initBaiduStatistics() {
  const env = process.env.NODE_ENV;
  if (env === 'production') {
    function baidu1() {
      var _hmt = _hmt || [];
      window._hmt = _hmt;
      (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?fec6e767e4b0544eb268c5da5042b696";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
    }


    function baidu2() {
      var _hmt = _hmt || [];
      window._hmt = _hmt;
      (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?f9b4e01ec5591f600ea1fc75c2bd7863";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
    }

    baidu1();
    baidu2();
    console.log('execute!');
  }
}
