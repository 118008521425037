export const scrollPosition = {

  namespaced: true,
  state: {
    message: {
      profit: 0,
      all: 0,
      task: ''
    },
    home: {
      plan: 0,
      rank: 0,
      collection: 0,
      rankPlanType: 0,
      rankCollect: 0,
      rankHotScore: 0,
      rankRecommended: 0
    },
    // 任意页面
    pages: [],
    ai: 0,
    creatorPage: 0
  },


  mutations: {

    SET_MESSAGE_SCROLL(state, payload) {
      payload = payload || {}
      for (const key in payload) {
        state.message[key] = payload[key]
      }
    },


    SET_HOME_SCROLL(state, payload) {
      payload = payload || {}
      for (const key in payload) {
        state.home[key] = payload[key]
      }
    },


  }

}
