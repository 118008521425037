import {v2Request} from "./v2Request";


/**
 * 用户相关api
 */
export const userV2Api = {

  async findMyInviteCode() {
    return v2Request.get(`/user/findMyInviteCode`)
  },

  // 查询我的vip信息
  async findMyVip() {
    return v2Request.get('/user/findMyVip');
  },


  // 更新我的个人信息
  updateMy(model) {
    return v2Request.post('/user/updateMy',model)
  },


  findMyInviteData(){
    return v2Request.get('/search-svr/user/findMyInviteData')
  },

};
