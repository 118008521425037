// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/config/vant'
// import VueLazyload from "vue-lazyload";

import '@/assets/styles/common.less';
import '@/assets/styles/model.less';
import Toast from './components/toast'
// import {Dialog, ImagePreview, Image, Loading as VanLoading} from 'vant'
import Alert from '@/components/alert'
import Loading from '@/components/loading'
import config from '@/config/config'
import './filters/index'
import {initBaiduStatistics} from "./config/baiduStatistic";
import './directive/index';
import {taskFinishRecordV2Api} from "./api/v2/taskFinishRecordV2Api";
import {closeGlobalLoading, showGlobalLoading} from "./config/util";
import './global.less'
import {globalMixin} from "./mixins/globalMixin";
import Longpress from "./directive/longpress";

const {Dialog, ImagePreview, Image, Loading: VanLoading, Notify} = vant;

Vue.config.productionTip = false;
Vue.prototype.$toast = Toast;
Vue.prototype.$alert = Alert;
Vue.prototype.$notify = Notify
Vue.prototype.$imagePreview = ImagePreview;
Vue.use(Loading);
Vue.component(Image.name, Image);
Vue.component(VanLoading.name, VanLoading);
Vue.mixin(config);
Vue.use(Longpress)



Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/static/lazy/error.png',
  loading: '/static/lazy/loading.png'
});
Vue.mixin(globalMixin)


initBaiduStatistics();

/**
 * 获取search中的query
 */
function getQuery() {
  let search = window.location.search;
  if (!search || !search.length || search === '?') {
    return {};
  }
  search = search.slice(1);
  const split = search.split('&');
  const query = {};
  for (const s of split) {
    const idx = s.indexOf('=');
    if (idx === -1) {
      query[s] = ''
    } else {
      const key = s.slice(0, idx);
      const value = s.slice(idx + 1);
      query[key] = value;
    }
  }
  return query;
}


router.beforeEach((to, from, next) => {
  to.meta.$from = from;

  // 判断是否为pc端登录，如果是，则跳转至pc端
  if (document.body.clientWidth >= 1000) {
    let pcPath = to.meta.pcPath || '/';
    if (pcPath.apply) {
      pcPath = pcPath(to) || '/';
    }

    const query = getQuery();
    let redirect = window.location.href = 'https://www.001ppt.com' + pcPath;
    if (query.shareUserId) {
      if (!redirect.includes('?')) {
        redirect += '?shareUserId=' + query.shareUserId;
      } else {
        redirect += '&shareUserId=' + query.shareUserId;
      }
    }
    return window.location.href = redirect;
  }

  let obj = to.meta;
  if (obj.auth) {
    if (!store.state.token) {
      store.state.to = to.path;
      // next('/login/wechat');
      return store.dispatch('showLoginDialog');
    } else {
      // if (!store.state.userInfo.mobile) {
      //   // next('/login/bindTel');
      //   store.state.isLoginSetMobile = true;
      //   return store.dispatch('showLoginDialog');
      // } else {
        NProgress.start();
        //document.title = obj.name;
        next();
      // }
    }
  } else {
    NProgress.start();
    //document.title = obj.name;
    next();
  }
});


router.afterEach(to => {
  if (to.name !== 'home' && to.name !== 'caseDetail') {
    setTimeout(() => {
      closeGlobalLoading();
    }, 300)
  }
  NProgress.done();
});
// Vue.mixin(historyMixin);

Vue.prototype.$bus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');


showGlobalLoading();
taskFinishRecordV2Api.notifyByTaskId(1);
taskFinishRecordV2Api.finishEverydayFirstLogin();
setInterval(() => {
  taskFinishRecordV2Api.notifyByTaskId(7);
}, 60000);
store.dispatch('config/loadConfig')
store.dispatch('checkTokenValid')
store.dispatch('ticket/loadAllTickets')
store.dispatch('buryPoint/startSaveInterval')
