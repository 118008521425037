function mobileFilter(val) {
  if (!val || !val.length || !val.slice) {
    return '';
  }

  if (val.length < 11) {
    return val.slice(0, 1) + '*'.repeat(val.length - 1);
  }
  return val.slice(0, 3) + '****' + val.slice(7);
}


Vue.filter('mobile',mobileFilter);
