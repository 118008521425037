import {planListV2Api} from "../api/v2/planListV2Api";

export const planListDetail = {
  namespaced: true,
  state: {
    planLists: [],
    columnsList: [],
    scrollTops: [],
    hasShowVersionPlanListIds: []
  },


  mutations: {

    ADD_PLAN_LIST(state, planList) {
      state.planLists.push(planList)
    },


    ADD_COLUMNS(state, {columns, planListId}) {
      state.columnsList.push({columns, planListId})
    },


    UPDATE_PLAN_LIST(state, planList) {
      const index = state.planLists.findIndex(p => p.id === planList.id)
      if (index === -1) {
        state.planLists.push(planList)
      } else {
        state.planLists.splice(index, 1, planList)
      }
    },


    UPDATE_SCROLL_TOP(state, {planListId, scrollTop}) {
      const index = state.scrollTops.findIndex(p => p.planListId === planListId)
      if (index === -1) {
        state.scrollTops.push({planListId, scrollTop})
      } else {
        state.scrollTops.splice(index, 1, {planListId, scrollTop})
      }
    },

    ADD_HAS_SHOW_VERSION_PLAN_LIST_ID(state, planListId){
      state.hasShowVersionPlanListIds.push(planListId)
    }

  },


  actions: {

    // 加载合集
    async loadPlanList({state, commit}, {id}) {
      const idx = state.planLists.findIndex(p => p && p.id === id)
      if (idx === -1) {
        const planListRes = planListV2Api.findAppletDetailById(id)
        const columnsRes = planListV2Api.findColumnsByPlanListId(id)
        const [planList, columns] = await Promise.all([planListRes, columnsRes])
        commit('ADD_PLAN_LIST', planList)
        commit('ADD_COLUMNS', {columns, planListId: id})
      } else {
        try {
          const planList = state.planLists[idx]
          planList.pay = await planListV2Api.findHasUnlock(id)
          commit('UPDATE_PLAN_LIST', planList)
        } catch (e) {

        }
        // const res = await planListV2Api.findAppletDetailById(id)
        // commit('UPDATE_PLAN_LIST', res)
      }
    }

  }

}
