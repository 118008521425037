export default {
  computed: {

    hasLogin() {
      const userInfo = this.$store.state.userInfo
      return userInfo && Object.keys(userInfo).length
    }

  },

  data() {
    return {
      appId: process.env.VUE_APP_APPID,
      debug: true,
      locationHref: `${process.env.VUE_APP_URL}/wx`,
      browser: ''
    }
  },

  methods: {


    // 默认头像
    formatAvatar(val) {
      return val || 'https://cdn.001ppt.cn/pc2/static/imgs/jpg/defaultAvatar.jpg';
    },


    // oss改变尺寸
    ossResize(val, width, height) {
      if (!val || !val.length) {
        return '';
      }

      return `${val}?x-oss-process=image/resize,w_${width},h_${height}`
    },


    // 网站自己压缩的图
    // https://cdn.oss.com/xx/yy.jpg -> https://cdn.oss.com/xx/yy_${width}.jpg
    imgThumb(url, width) {
      url = url || ''
      const lastDotIdx = url.lastIndexOf(".")
      const ext = url.slice(lastDotIdx)
      const idx = url.lastIndexOf(ext)
      const left = url.slice(0, idx)
      return `${left}_${width}${ext}`
    },


    //格式化数量
    numWanFilter(value) {
      if (!value) return 0;
      value = parseInt(value);
      if (value > 10000) {
        value = value.toString();
        let frist_init = value.slice(0, value.length - 4);//取value后四位之外的数值，例如：32100 取3
        let last_four = value.substr(value.length - 4);//取value后四位，例如：32100 取2100
        return `${frist_init}${last_four.slice(0, 2) === "00" ? "" : "." + last_four.slice(0, 2)}万`;
      } else {
        return value;
      }
    },

    //显示文件格式
    showFileType(mime) {
      if (!mime) {
        return '未知';
      } else {
        return mime.toUpperCase();
      }
    },
    //设置显示文件大小
    setFileSize(size, type) {
      const value = (size / (1024 * 1024)).toFixed(2);
      if (type) {
        return `${(value / 1024).toFixed(2)}G`;
      }
      if (value >= 1024) {
        return `${(value / 1024).toFixed(2)}G`;
      } else {
        return `${value}M`;
      }


    },

    //裁剪城市 选择市
    clipCity(city) {
      if (!city) return '未知';
      if (!city.length) return '未知';
      const split = city.split(',');
      return split[1] || split[0] || '未知';
    },

    setVal(val) {
      if (!val) return '未知';
      return val;
    },

    //浏览器
    getBrowser() {
      const ua = window.navigator.userAgent.toLowerCase();
      let result = '';
      if (ua.match(/MicroMessenger/i) === null) {
        result = 'h5';
      } else {
        result = 'wechat';
      }
      // console.log(`当前使用的浏览器为：${result}`);
      return result;
    },


    formatUsername(val) {
      if (!val) {
        return ''
      }
      if (val.length > 6) {
        return val.slice(0, 6) + '...'
      } else {
        return val
      }
    },

    diffDays(d1, d2) {
      d2 = d2 || Date.now()
      const ms = Math.abs(new Date(d1).getTime() - new Date(d2).getTime())
      return Math.floor(ms / 1000 / 60 / 60 / 24)
    },

    //格式化年月日
    formatDate(d, formatter) {
      if (!d) return '';
      let dateStr = d.replace(/\-/g, "/");
      const date = new Date(dateStr);
      const nowDate = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const nowYear = nowDate.getFullYear();
      const rst = `${year === nowYear ? '' : year + '/'}${this.formatMun(month)}/${this.formatMun(day)} ${this.formatMun(hours)}:${this.formatMun(minutes)}`;

      if (formatter === 'date') {
        return rst.slice(0, 10).replace(/\//g, '-')
      }
      return rst
    },

    formatMun(num) {
      return `${num >= 10 ? num : `0${num}`}`;
    },

    setTag(tags) {
      if (!tags) return [];
      return tags.split(',');
    },

    //发送验证码
    async sendPhone(phone) {
      return this.$http.get(`/mp/user/sendSms`, {params: {phone}});
    },

    //隐藏手机号中间4位数
    hidePhone(tel) {
      if (!tel || !tel.length) {
        return '';
      }
      return tel.length ? tel.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2") : '';
    },

    showImg(url) {
      return process.env.VUE_APP_ASSETS_URL + url;
    },


    //   微信选择图片方法    type === 'album', 'camera'
    chooseImage(type) {
      return new Promise((resolve, reject) => {
        wx.chooseImage({
          count: 1, // 默认9
          sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: [type], // 可以指定来源是相册还是相机，默认二者都有
          success(res) {
            // var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
            resolve(res);
          },
          cancel() {
            reject();
          }
        });
      });

    },

    //输入验证
    validate(data, msg, type) {
      let result = true;
      if (!type) {
        result = data != '';
      } else {
        switch (type) {
          case 'tel':
            result = (/^1[3456789]\d{9}$/.test(data));
            break;
          case 'length':
            result = data.length !== 0;
            break;
        }
      }
      if (!result) {
        this.$toast.error(msg);
      }
      return result;
    },

    secondsToDayInfo(second) {
      if (!second) {
        return '-';
      }
      const d = Math.floor(second / 60 / 60 / 24);
      second -= d * 24 * 60 * 60;
      const h = Math.floor(second / 60 / 60);
      second -= h * 60 * 60;
      const m = Math.floor(second / 60);
      second -= m * 60;
      return (h ? `${h}时` : '') + (m ? `${m}分` : '') + (second ? `${second}秒` : '')
    },

    isInfinityExpire(val) {
      if (!val) {
        return false
      }

      const d = new Date(val.replace(/-/g, '/'))
      const maxDate = new Date(2100, 1, 1, 0, 0, 0, 0)
      return d.getTime() > maxDate.getTime()
    },


    // 严值显示
    yanzhiFilter(val, maxVal) {
      if (val > maxVal) {
        return maxVal + '+'
      }
      if (!val) {
        return 0;
      }

      val = parseFloat(val).toFixed(1);
      var valStr = val + '';
      if (valStr.length <= 2) {
        return val;
      }

      if (valStr[valStr.length - 2] === '.' && valStr[valStr.length - 1] === '0') {
        return parseInt(valStr);
      }

      return val;
    },

    // 钱显示
    moneyFilter(val, bits = 2, maxVal) {
      if (val > maxVal) {
        return maxVal + '+'
      }
      if (!val) {
        return 0;
      }
      val = parseFloat(val)
      if ((val - parseInt(val)) === 0) {
        return val.toFixed(0)
      }
      val = val * 10;
      if ((val - parseInt(val)) === 0) {
        return (val / 10).toFixed(1);
      }
      return (val / 10).toFixed(2)
    },


    yanzhiAndMoneyFilter(val, maxVal) {
      console.log(val, maxVal)
      if (!val) {
        return '0(¥0)'
      }
      if (maxVal && val > maxVal) {
        return `${this.yanzhiFilter(maxVal)}+(¥${this.moneyFilter(maxVal / 10)}+)`
      }
      return `${this.yanzhiFilter(val)}(¥${this.moneyFilter(val / 10)})`
    },

    ossBlur(val, r, s, needProcess) {
      // if (!val || !val.length) {
      //   return val;
      // }
      // if (!needProcess) {
      //   return val;
      // }
      // const processor = `image/blur,r_${r},s_${s}`;
      //
      // if (val.indexOf('x-oss-process=image') !== -1) {
      //   return val + `,${processor}`;
      // } else {
      //   return val + `?x-oss-process=${processor}`
      // }
      return val;
    },

    // hasLogin() {
    //   const userInfo = localStorage.getItem('userinfo');
    //   const token = localStorage.getItem('token');
    //   return token && token.length && userInfo && userInfo.length;
    // },


    fileExtFilter(val) {

      if (!val || !val.length) {
        return '';
      }
      val = val.toLowerCase();
      if (val === 'ppt' || val === 'pptx') {
        return 'PPT'
      } else if (val === 'pdf') {
        return 'PDF'
      } else if (val === 'xls' || val === 'xlsx') {
        return 'EXCEL'
      } else if (val === 'zip' || val === 'rar' || val === '7z' || val === 'gz') {
        return 'ZIP'
      }
      return val;
    },


    fansNumFilter(val, minVal) {
      if (!val) {
        return 0;
      }

      val = parseInt(val);
      if (isNaN(val)) {
        return 0;
      }

      if (minVal && val < minVal) {
        return val
      }

      if (val >= 10000) {
        val = (val / 10000).toFixed(1);
        if (parseFloat(val) === parseInt(val)) {
          val = parseInt(val);
        }
        return `${val}w`
      }

      if (val >= 100) {
        val = (val / 1000).toFixed(1);
        if (parseFloat(val) === parseInt(val)) {
          val = parseInt(val);
        }
        return `${val}k`
      }

      return val;
    },


    // 获取vipIcon地址
    getVipIcon(vipRightId) {
      switch (vipRightId) {
        case 2:
        case '2':
        case '入门会员':
          return 'https://cdn.001ppt.cn/pc2/static/imgs/svg/base-vip-icon.svg';
        case 4:
        case '4':
        case '超级VIP会员':
          return 'https://cdn.001ppt.cn/pc2/static/imgs/svg/super-vip-icon.svg';
        case 5:
        case '5':
        case '俱乐部会员':
          return 'https://cdn.001ppt.cn/pc2/static/imgs/svg/club-vip-icon.svg';
        case 6:
        case '6':
        case 'AI会员':
          return 'https://cdn.001ppt.cn/pc2/static/imgs/svg/ai-vip-icon.png';
        default:
          return '';
      }
    },

    getVipIconNew(vipRightId) {
      switch (vipRightId) {
        case 2:
        case '2':
        case '入门会员':
          return `https://cdn.001ppt.cn/pc2/static/imgs/svg/base-vip-icon.svg`;
        case 4:
        case '4':
        case '超级VIP会员':
          return 'https://cdn.001ppt.cn/pc2/static/imgs/svg/super-vip-icon-2.svg';
        case 5:
        case '5':
        case '俱乐部会员':
          return 'https://cdn.001ppt.cn/pc2/static/imgs/svg/club-vip-icon-2.svg';
        case 6:
        case '6':
        case 'AI会员':
          return 'https://cdn.001ppt.cn/pc2/static/imgs/svg/ai-vip-icon-2.svg';
        default:
          return '';
      }
    },
  },

}


export function getDevice() {
  let webLog = {}
  let userAgent = navigator.userAgent
  // 获取微信版本
  let m1 = userAgent.match(/MicroMessenger.*?(?= )/)
  if (m1 && m1.length > 0) {
    webLog.wechat = m1[0]
  }
  // 苹果手机
  if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
    // 获取设备名
    if (userAgent.includes('iPad')) {
      webLog.device = 'iPad'
    } else {
      webLog.device = 'iPhone'
    }
    // 获取操作系统版本
    m1 = userAgent.match(/iPhone OS .*?(?= )/)
    if (m1 && m1.length > 0) {
      webLog.system = m1[0]
      console.log(webLog.system)
    }
  }
  // 安卓手机
  if (userAgent.includes('Android')) {
    // 获取设备名
    m1 = userAgent.match(/Android.*; ?(.*(?= Build))/)
    if (m1 && m1.length > 1) {
      webLog.device = m1[1]
      console.log(webLog.device)
    }
    // 获取操作系统版本
    m1 = userAgent.match(/Android.*?(?=;)/)
    if (m1 && m1.length > 0) {
      webLog.system = m1[0]
    }
  }

  return webLog.system
}
