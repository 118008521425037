import {v2Request} from "./v2Request";


/**
 * 用户相关api
 */
export const configV2Api = {

    async inviteShare() {
        return v2Request.get(`/config/inviteShare`);
    },

    async findByCodes(codes) {
        return v2Request.post(`/search-svr/config/findByCodes`, codes);
    },

};
