<template>
    <div class="page" :class="child?'child-page': 'full-page'">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "page",
        props: {
            child: Boolean,
            default: false
        }
    }
</script>

<style scoped lang="less">
    .page{
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
        &.child-page{
            height: calc(100vh - @tabbar-height - 1px);
        }
        &.full-page{
            height: 100vh;
        }
    }
</style>
