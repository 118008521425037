

export const previewSingleImage = {
  namespaced: true,

  state:{
    visible: false,
    options:{
      width: '90vw',
      fit: '',
      height: '',
      src: ''
    }
  },


  mutations:{

    show(state,options){
      options = options || {}
      state.options.width = options.width || state.options.width
      state.options.height = options.height || state.options.height
      state.options.fit = options.fit || state.options.fit
      state.options.src = options.src || state.options.src
      state.visible = true
    },


    hide(state){
      state.visible = false
      state.options = {
        width: '90vw',
        fit: '',
        height: '',
        round: true
      }
    }
  }

}
