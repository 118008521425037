
// import Vue from "vue"; // 引入 Vue 是因为要用到 Vue.extend() 这个方法
import Main from "./alert.vue";
import Toast from "../toast"; // 引入刚才的 toast 组件

let AlertConstructor = Vue.extend(Main); // 这个在前面的前置知识内容里面有讲到
let instance;

const Alert = function(data) {
    return new Promise((resolve, reject) => {
        instance = new AlertConstructor({
            data:{
                ...data,
                show: false
            },
            methods: {
                close(){
                    this.show = false;
                    setTimeout(_ => {
                        document.body.removeChild(this.$el);
                    },100);
                    reject();
                },
                submit(){
                    resolve(this.close);
                }
            }
        }); // 渲染组件
        document.body.appendChild(instance.$mount().$el); // 挂载到 body 下
    })

};


export default Alert;
