

export const globalMixin = {

  computed:{

  },

  methods:{
    jsonObj(json){
      return JSON.parse(json||'{}')
    },

    jsonArr(json){
      return JSON.parse(json||'[]')
    }
  }

}
