import {v2Request} from "./v2Request";

const proms = {
  _findConfigByType: {}
}


export const ticketV2Api = {

  // 查找我的优惠券
  async findMy() {
    return v2Request.get(`search-svr/ticket/findMy`)
  },


  async updateMyHasShown(id) {
    return v2Request.post(`userTicket/updateHasShown`,{id})
  },

  async findConfigByType(type) {
    if (!proms._findConfigByType[type]) {
      proms._findConfigByType[type] = v2Request.get('ticketConfig/findByType', {params: {type}});
    }
    return proms._findConfigByType[type];
  },

};
