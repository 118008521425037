// import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/api'


Vue.use(Vuex);
import {user} from "@/api";
import {share} from "./share";
import {config} from "./config";
import {userV2Api} from "../api/v2/userV2Api";
import {previewSingleImage} from "./previewSingleImage";
import {ticket} from "./ticket";
import {userPreference} from "./userPreference";
import {buryPoint} from "./buryPoint";
import {rankPage} from "./rankPage";
import {scrollPosition} from "./scrollPosition";
import {planListDetail} from "./planListDetail";


export default new Vuex.Store({
  namespaced: true,
  modules: {
    share,
    config,
    previewSingleImage,
    ticket,
    userPreference,
    buryPoint,
    rankPage,
    scrollPosition,
    planListDetail
  },
  state: {
    wxInfo: localStorage.getItem('wxInfo') ? JSON.parse(localStorage.getItem('wxInfo')) : {},
    token: localStorage.getItem('token') || '',
    userInfo: localStorage.getItem('userinfo') ? JSON.parse(localStorage.getItem('userinfo')) : {}, //用户信息
    member: localStorage.getItem('member') ? JSON.parse(localStorage.getItem('member')) : {},       //会员信息
    wallet: localStorage.getItem('wallet') ? JSON.parse(localStorage.getItem('wallet')) : {},           //钱包
    tag: [],            //搜索的标签
    to: '/home',
    loginDialogVisible: false,
    isLoginSetMobile: false,
    loginOnlyByWx: false,
    isFreeCaseUnitDay: false,
    caseDetailPagerVisible: true
  },
  mutations: {
    HIDE_DETAIL_PAGER(state) {
      state.caseDetailPagerVisible = false
    },
    SET_IS_FREE_CASE_UNIT_DAY(state, value) {
      state.isFreeCaseUnitDay = value;
    },
    SHOW_LOGIN_DIALOG(state, data) {
      data = data || {
        loginOnlyByWx: false
      };
      state.loginOnlyByWx = data.loginOnlyByWx;
      state.loginDialogVisible = true;
    },
    SET_LOGIN_REDIRECT(state, redirect) {
      state.to = redirect || '/home'
    },
    HIDE_LOGIN_DIALOG(state, data) {
      state.loginDialogVisible = false;
    },
    //设置微信用户信息
    SET_WX_INFO(state, data) {
      state.wxInfo = data;
    },

    //设置token
    SET_TOKEN(state, token) {
      localStorage.setItem('token', token);
      state.token = token;
    },

    //设置用户信息
    SET_USERINFO(state, data) {
      localStorage.setItem('userinfo', JSON.stringify(data));
      state.userInfo = data;
    },


    //设置用户会员信息
    SET_MEMBER(state, data) {
      localStorage.setItem('member', JSON.stringify(data));
      state.member = data;
    },


    //设置搜索标签
    SET_TAG(state, data) {
      state.tag = data;
    },


    //设置钱包信息
    SET_WALLET(state, data) {
      localStorage.setItem('wallet', JSON.stringify(data));
      state.wallet = data;
    },

  },
  actions: {

    async checkTokenValid({commit}) {
      const token = localStorage.getItem('token')
      let userInfo = {}
      if (!token || !token.length) {
        return commit('SET_USERINFO', userInfo)
      }
      const userRes = await api.user.userInfo();
      userInfo = userRes.data
      if (userInfo && Object.keys(userInfo)) {
        commit('SET_USERINFO', userInfo)
      } else {
        commit('SET_TOKEN', '')
        commit('SET_USERINFO', {})
        commit('SET_MEMBER', {})
      }
    },

    //获取钱包信息
    async getWallet({commit}) {
      const {data} = await user.wallet();
      commit('SET_WALLET', data);
    },


    async getMemberInfo({commit}) {
      const {data} = await user.memberInfo();
      commit('SET_MEMBER', data);
    },

    hideLoginDialog({commit}) {
      commit('HIDE_LOGIN_DIALOG');
    },
    showLoginDialog({commit}) {
      commit('SHOW_LOGIN_DIALOG')
    }
  },
  getters: {
    wxInfo: state => state.wxInfo,
    userInfo: state => state.userInfo,
    userVip: state => state.member,
    token: state => state.token,
    wallet: state => state.wallet,
    tag: state => state.tag,
    loginDialogVisible: state => state.loginDialogVisible,
    isLoginSetMobile: state => state.isLoginSetMobile,
    loginOnlyByWx: state => state.loginOnlyByWx
  }
})
