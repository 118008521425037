// import Vue from 'vue';
import Page from '@/components/page'
import Card from '@/components/card'
import VHeader from '@/components/header'
// import {
//     Icon,
//     Tabs,
//     Tab,
//     CellGroup,
//     Cell,
//     Field,
//     Search,
//     Uploader,
//     Button,
//     Tabbar,
//     TabbarItem,
//     ActionSheet,
//     Dialog,
//     List,
//     PullRefresh,
//     Area,
//     Popup,
//     Picker,
//     Overlay,
//     RadioGroup,
//     Radio,
//     Calendar,
//     Checkbox,
//     CheckboxGroup,
//     Empty,
//     DropdownMenu,
//     DropdownItem,
//     Popover,
//     Swipe,
//     SwipeItem,
//     IndexBar,
//     IndexAnchor,
//     ShareSheet,
//     Collapse,
//     CollapseItem
// } from "vant";
const {
  Icon,
  Tabs,
  Tab,
  CellGroup,
  Cell,
  Field,
  Search,
  Uploader,
  Button,
  Tabbar,
  TabbarItem,
  ActionSheet,
  Dialog,
  List,
  PullRefresh,
  Area,
  Popup,
  Picker,
  Overlay,
  RadioGroup,
  Radio,
  Calendar,
  Checkbox,
  CheckboxGroup,
  Empty,
  DropdownMenu,
  DropdownItem,
  Popover,
  Swipe,
  SwipeItem,
  IndexBar,
  IndexAnchor,
  ShareSheet,
  Collapse,
  CollapseItem
} = vant;

let components = [
  Icon,
  Tabs,
  Tab,
  CellGroup,
  Cell,
  Field,
  Search,
  Uploader,
  Button,
  Tabbar,
  TabbarItem,
  ActionSheet,
  Dialog,
  List,
  PullRefresh,
  Area,
  Popup,
  Picker,
  Overlay,
  RadioGroup,
  Radio,
  Calendar,
  Checkbox,
  CheckboxGroup,
  Empty,
  DropdownMenu,
  DropdownItem,
  Popover,
  Swipe,
  SwipeItem,
  IndexBar,
  IndexAnchor,
  ShareSheet,
  Collapse,
  CollapseItem
];

components.forEach(item => {
  Vue.use(item);
});
Vue.component('Page', Page);
Vue.component('Card', Card);
Vue.component('VHeader', VHeader);
