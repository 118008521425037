import {v2Request} from "./v2Request";

let findTodayRankProm = null

/**
 * 方案相关接口
 */
export const planV2Api = {

  async findVisibleOption() {
    return v2Request.get(`/plan/findVisibleOption`);
  },

  async findRefPlanLists(planId) {
    return v2Request.get(`/plan/findRefPlanLists?planId=${planId}`);
  },


  async findMyPublish({current, size}) {
    return v2Request.get(`/plan/findMyPublish?current=${current}&size=${size}`);
  },


  async findPublishByUserId({userId, current, size}) {
    return v2Request.get(`/plan/findPublishByUserId?current=${current}&size=${size}&userId=${userId}`);
  },


  async toggleCollect({planId}) {
    return v2Request.post(`/plan/toggleCollect`, {planId})
  },


  async toggleRecommend({planId}) {
    return v2Request.post(`/plan/toggleRecommend`, {planId})
  },


  // 查找用户推荐
  async findUserRecommend({userId, current, size}) {
    return v2Request.get('/planRecommend/findByUserId', {params: {userId, current, size}});
  },


  async getDownloadUrl({planId}) {
    return v2Request.get('/planPay/getPlanDownloadUrl', {params: {planId}})
  },


  async captcha() {
    return v2Request.get('/plan/captcha')
  },


  async findPlanIsOnline(planId) {
    return v2Request.get('/search-svr/plan/findPlanIsOnline', {params: {planId}})
  },


  async findTodayRank() {
    if (!findTodayRankProm) {
      findTodayRankProm = v2Request.get('/search-svr/plan/findTodayRank')
    }
    return findTodayRankProm
  },


  findDetailByIdForApplet(id) {
    return v2Request.get(`/search-svr/plan/findDetailByIdForApplet?id=${id}`)
  },


  async findRankInfo(planId) {
    return v2Request.get(`/search-svr/planRank/findLastByPlanId`, {params: {planId}})
  },

  async findMemberFreeAndNeedPayPlanSum() {
    return v2Request.post(`/plan/findMemberFreeAndNeedPayPlanSum`);
  },


  findDownloadInfo({planId, verifyCode,visitorToken}) {
    return v2Request.get(`/search-svr/download/plan`, {
      params:{
        planId, verifyCode,visitorToken
      }
    })
  },


};
