<template>
  <div>
    <header :class="[(deep && !theme)&&'deep',theme && 'theme']">
      <template>
        <div class="back" @click="goBack" v-if="!$slots.back">
          <i class="iconfont icon-arrow" :class="deep&&'deep'"></i>
        </div>
        <slot name="back" v-else></slot>

      </template>
      <div class="search" v-if="searchVisible" @click="$router.push({path: `/discover?focusInput=true`})">
        快速找到好资料
        <i class="iconfont icon-search"></i>
      </div>
      <span class="title" :class="deep&&'deep'" v-if="!useSlot">
          {{ name && name.length ? name : '' }}
        </span>
      <span class="title" v-else :class="deep&&'deep'">
      <slot></slot>
    </span>

      <template>
        <div class="home" @click="$router.replace('/home')" v-if="!$slots.home">
          <i class="iconfont icon-home" :class="deep&&'deep'"></i>
        </div>
        <slot name="home" v-else></slot>
      </template>
    </header>
    <div class="header-holder" v-if="useHolder"></div>
  </div>
</template>

<script>
export default {
  name: "header",
  props: {
    title: '',
    deep: {
      type: Boolean,
      default: false
    },
    theme: {
      type: Boolean,
      default: false
    },
    searchVisible: {
      type: Boolean,
      required: false
    },
    useSlot: {
      type: Boolean,
      required: false
    },
    useHolder: {
      type: Boolean
    }
  },
  watch: {
    title(v) {
      this.name = v;
    }
  },
  data() {
    return {
      name: ''
    }
  },
  created() {
    this.name = this.title || this.$route.meta.name;
  },

  methods: {
    // 返回上一层
    goBack() {
      const meta = this.$route.meta ? {...this.$route.meta} : {};
      const {$from} = meta;
      if (!$from || !$from.meta || !$from.meta.keepAlive) {
        return this.$router.go(-1)
      }
      this.$router.replace({path: $from.fullPath});
    }
  }
}
</script>

<style scoped lang="less">
@header-height: 40px;
header {
  box-sizing: border-box;
  height: var(--header-height);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  border-bottom: 1px solid var(--grey-bg);
  z-index: 30;

  &.deep {
    background: #000;
    border: none;
  }

  &.theme {
    background: var(--text-color);
    border: none;
  }

  .back {
    position: absolute;
    left: 0;
    height: @header-height;
    width: @header-height;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;

    i {
      font-size: 15px;
      transform: rotate(180deg);
      color: var(--text-color);
    }

    i.deep {
      color: #fff;
    }
  }

  .home {
    position: absolute;
    right: 0;
    height: @header-height;
    width: @header-height;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;

    i {
      font-size: 16px;
      color: var(--text-color);
    }

    i.deep {
      color: #fff;
    }
  }

  span {
    color: var(--header-height);
    display: block;
    width: 100vw;
    box-sizing: border-box;
    padding: 0 @header-height;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;

    &.deep {
      color: #fff;
    }
  }
}

.search {
  position: absolute;
  left: 40px;
  right: 40px;
  color: rgba(21, 29, 54, 0.3);
  background-color: rgba(21, 29, 54, 0.05);
  height: calc(@header-height - 16px);
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding-left: 12px;

  .icon-search {
    position: absolute;
    right: 8px;
    transform: translateY(1px);
    font-size: 14px;
  }
}

.header-holder{
  height: @header-height;
  width: 100%;
}
</style>
